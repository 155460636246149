import React, { FC, useState } from 'react'
import { Options } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import style from '../Styles/index.module.scss'
import { Card, Collapse } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { deleteProduct } from '../../../store/actions/productActions'

interface IProductCardBody {
  name: string
  status: string
  version: string
  guid: string
  path: string
}

const ProductCardBody: FC<IProductCardBody> = ({ name, status, version, guid, path }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [isAppNameHovered, setIsAppNameHovered] = useState(false)
  const onAppNameHover = () => setIsAppNameHovered(true)
  const onAppNameLeave = () => setIsAppNameHovered(false)

  const showPathHandle = (): void => {
    setOpen(prev => !prev)
  }

  const handleOnDelete = (guid: string, productName: string): void => {
    dispatch(showModal(ModalTypes.MAIN_MODAL, { body: `Delete ${productName}?`, onSubmit: () => dispatch(deleteProduct(guid)) }))
  }

  return (
    <Card.Body>
      <div className="mt-2">
        <p className={style.descriptionAppName}>
          <span className="app-color-text fw-500 mr-1">App name:</span>
          {!isAppNameHovered ? (
            <span className={style.descriptionNotHovered} onMouseEnter={onAppNameHover}>
              {name}
            </span>
          ) : (
            <OverlayScrollbarsComponent
              className={style.descriptionHovered}
              options={{ overflowBehavior: { x: 'scroll', y: 'visible-hidden' } } as Options}
              onMouseLeave={onAppNameLeave}
            >
              {name}
            </OverlayScrollbarsComponent>
          )}
        </p>
        <p className={style.description}>
          <span className="app-color-text fw-500 mr-1">Status:</span> {status.toLowerCase()}{' '}
          {status.toLowerCase() === 'active' ? <span className={style.active} /> : <span className={style.inactive} />}
        </p>
        <p className={style.description}>
          <span className="app-color-text fw-500 mr-1">Version:</span> {version}
        </p>

        <div>
          <div className={style.control}>
            <button type="button" onClick={showPathHandle} className="app-btn-apply app-btn-main app-btn-large" aria-controls={guid} aria-expanded={open}>
              {!open ? 'Show' : 'Hide'} full path
            </button>
            <button type="button" onClick={() => handleOnDelete(guid, name)} className='app-btn-delete app-btn-main app-btn-large'>
              Delete
            </button>
          </div>
          <Collapse in={open}>
            <div id={guid} className="border p-1 rounded mt-1">
              {path || '/'}
            </div>
          </Collapse>
        </div>
      </div>
    </Card.Body>
  )
}

export default ProductCardBody
