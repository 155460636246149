export enum NavBarProductsTypes {
  TEST_PRODUCT = "TEST_PRODUCT",
}

export enum NavBarActionType {
  ROUTE_PRODUCT = "ROUTE_PRODUCT",
  UNROUTE_PRODUCT = "UNROUTE_PRODUCT"
}
  

class NavBarState {
  productType: string | null

  constructor(productType = null) {
    this.productType = productType
  }
}
  
export function navBarReducer(state = new NavBarState(), action: any) {
  switch (action.type) {
    case NavBarActionType.ROUTE_PRODUCT:
      return {
        ...state,
        productType: action.payload.productType
      }
    case NavBarActionType.UNROUTE_PRODUCT:
      return new NavBarState()
    default:
      return state
  }
}