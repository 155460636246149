import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import style from './Styles/index.module.scss'
import { useDispatch } from 'react-redux'
import { ModalTypes } from '../../../types/modals'
import { showModal } from '../../../store/actions/modalActions'
import { deleteProduct } from '../../../store/actions/productActions'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutesPaths } from '../../../router'

interface IEditConfigBtn {
  guid: string
  productName: string
  handleOnRename: any
}

const EditConfigBtn: FC<IEditConfigBtn> = ({ guid, productName, handleOnRename }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentLocation = useLocation().pathname

  const handleOnDelete = (): void => {
    dispatch(
      showModal(ModalTypes.MAIN_MODAL, {
        body: `Delete ${productName}?`,
        onSubmit: (): void => {
          if (currentLocation.includes(guid)) {
            dispatch(deleteProduct(guid, () => history.push(RoutesPaths.SUMMARY)))
          } else {
            dispatch(deleteProduct(guid))
          }
        },
      }),
    )
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className={style.button} variant="outline-secondary" id="dropdown-basic">
        <FontAwesomeIcon icon={faEllipsisH} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.menu}>
        <Dropdown.Item eventKey="1" onClick={handleOnDelete}>
          <FormattedMessage id="remove" />
        </Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={handleOnRename}>
          <FormattedMessage id="rename" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default EditConfigBtn
