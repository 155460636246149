import { getPermissions, getUsername } from '../store/actions/authentication'

export enum UserPermissions {
  USER_MANAGEMENT = 'user_management',
  CONFIGURATION_MANAGEMENT = 'configuration_management',
  CONFIGURATION_ADMINISTRATION = 'configuration_administration',
}

interface IUser {
  login: string
  permissions: string[]
}

export class User implements IUser {
  public login: string
  public permissions: string[]

  constructor() {
    this.login = getUsername()
    this.permissions = getPermissions()
  }
}
