import { ProductEntity } from '../../entity/Product'
import { IProduct, ProductActionType } from '../../types/product'

const initialState: any = { products: [], isLoading: false, error: null, productNameChangeCounter: 0, isProductRouted: false }

export const productsReducer = (state = initialState, action: { type: string; payload: any }): any => {
  switch (action.type) {
    case ProductActionType.FETCH_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      }
    case ProductActionType.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload.map((product: IProduct) => new ProductEntity(product)),
      }
    case ProductActionType.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case ProductActionType.DELETE_PRODUCT:
      return {
        ...state,
      }
    case ProductActionType.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((product: IProduct) => product.guid !== action.payload?.guid),
      }
    case ProductActionType.DELETE_PRODUCT_ERROR:
      return {
        ...state,
      }

    case ProductActionType.PRODUCT_NAME_CHANGE:
      return {
        ...state,
        productNameChangeCounter: (state.productNameChangeCounter += 1),
      }

    case ProductActionType.SET_PRODUCT_ROUTED:
      return {
        ...state,
        isProductRouted: action.payload,
      }

    default:
      return state
  }
}
