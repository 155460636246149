import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { IPasswordInput } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PasswordInput: React.FC<IPasswordInput> = ({
  state,
  setState,
  touched,
  setTouched,
  errors,
  errorText,
  name,
  className,
  placeholder,
  label,
  isDisabled,
  tooltipText,
  isColumn,
  isLabelHiden,
  isRequired,
  inputType,
}) => {
  const [type, setType] = useState('password')
  const [isChanged, setIsChanged] = useState(false)

  const showPassword = (): void => {
    setType('string')
  }

  const hidePassword = (): void => {
    setType('password')
  }

  const handleChange = (event: any): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
    setState({
      ...state,
      [name]: event.target.value,
    })
    setIsChanged(true)
  }

  const handleBlur = (): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const setSecretState = () => {
    if (inputType === 'secret') {
      return !isChanged ? '******' : state?.[name]
    } else {
      return state?.[name]
    }
  }

  const dataState = setSecretState()

  const getValidationresult = (): boolean => touched && touched[name] && errors && errors[name]
  const isInvalid: boolean = getValidationresult()

  return (
    <Form.Group
      style={{ maxWidth: '600px' }}
      className={`${className} ${isInvalid && 'mb-4'} d-flex flex-1 mw-250 m-1 align-items-center justify-content-between`}
    >
      {label && !isLabelHiden ? (
        <Form.Label style={{ maxWidth: isColumn ? '30%' : 'auto' }} className={`label-main mt-1 text-capital ${!isColumn && 'mr-2'}`}>
          <span className="label-main-wrapper p-relative">
            {label}: {isRequired && <span className="app-color-text label-required-mark">*</span>}
          </span>
        </Form.Label>
      ) : null}
      <span style={{ width: '70%' }} className={`d-flex align-items-center ${!isColumn ? 'flex-1' : ''} p-relative`}>
        <span className="d-flex align-items-center flex-column w-100">
          <Form.Control
            className={`input-main ${!tooltipText ? 'input-padding-sm' : 'password-input-padding'}`}
            type={type}
            placeholder={placeholder}
            value={dataState}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!isDisabled}
            isInvalid={isInvalid}
            name={name}
          />
          <Form.Control.Feedback className="input-feedback" type="invalid">
            <FormattedMessage id={errorText || 'password-error'} tagName="span" />
          </Form.Control.Feedback>
        </span>
        <div className={`${isInvalid && 'mr-4'} input-tooltip d-flex align-items-center`}>
          <div onMouseDown={showPassword} onMouseLeave={hidePassword} onMouseUp={hidePassword}>
            <FontAwesomeIcon className="light-gray" icon={type === 'password' ? faEyeSlash : faEye} />
          </div>
          {tooltipText && <PromptBtn prompt={tooltipText ?? ''} />}
        </div>
      </span>
    </Form.Group>
  )
}

export default PasswordInput
