import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProducts } from '../../store/actions/productActions'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import SideBarItem from '../SideBarItem/SideBarItem'
import { routes } from '../../router'
import { User } from '../../entity/User'

interface ISideBar {
  sidebarHidden: boolean
}

const SideBar: FC<ISideBar> = ({ sidebarHidden }) => {
  const dispatch = useDispatch()
  const user = new User()

  const onLicenseClick = (): void => {
    dispatch(showModal(ModalTypes.LICENCE_MODAL, {}))
  }

  const licence: JSX.Element = (
    <div className="d-flex h-60px w-100 licence" onClick={onLicenseClick}>
      <div className={`${sidebarHidden ? 'd-flex m-auto' : 'd-flex align-items-center justify-content-center m-auto w-90'}`}>
        <FontAwesomeIcon icon={faInfo} size="1x" style={{ marginTop: '2px' }} className="text-white" />
        {sidebarHidden && <span className="text-light ml-3">TFB Web configurator</span>}
      </div>
    </div>
  )

  useEffect(() => {
    dispatch(fetchProducts())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchProducts()), 30000)
    return () => clearInterval(interval)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={['bg-dark', style.wrapper, sidebarHidden ? style.show : style.hidden].join(' ')}>
      <div className="w-100">
        <div className={style.barContainer}>
          {routes.map((item: any) => {
            if (user.permissions.includes(item.permission)) {
              return <SideBarItem name={item.name} route={item.path} icon={item.icon} dropdown={item.dropdown} sidebarHidden={sidebarHidden} />
            }
            return <></>
          })}
        </div>
      </div>
      {licence}
    </div>
  )
}

export default SideBar
