import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import '@t4b/core/lib/index.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'react-datepicker/dist/react-datepicker.css'
import { App } from './App'
import { store } from './store'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
      <App />
      </Provider>
    </BrowserRouter>,
  document.getElementById('root'),
)
