export const blockActions = {
  disableBlock(state: any, path: string[], name: string, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    let checked = false

    if (currentPathLength === path.length) {
      checked = true
      if (state?.[name]) {
        return (state[name] = null)
      }
      return
    }

    if (currentPathLength < path.length && !checked) {
      this.disableBlock(currentState?.[path[lenth - 1]], path, name, currentPathLength + 1)
    }
  },
  enableBlock(state: any, path: string[], name: string, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    let checked = false

    if (currentPathLength === path.length) {
      checked = true
      return (state[name] = data)
    }

    if (currentPathLength < path.length && !checked) {
      this.enableBlock(currentState?.[path[lenth - 1]], path, name, data, currentPathLength + 1)
    }
  },
}
