import axios from 'axios'
import { Dispatch } from 'redux'
import { ProductActionType } from '../../types/product'
import { throwErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { getJwt } from './authentication'

const API_PRODUCTS = `${apiVersion}/products`

export const fetchProducts = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ProductActionType.FETCH_PRODUCTS })
      const { data } = await axios.get(API_PRODUCTS, { headers: { Authorization: `Bearer ${getJwt()}` } })
      dispatch({ type: ProductActionType.FETCH_PRODUCTS_SUCCESS, payload: data })
    } catch (e) {
      dispatch({ type: ProductActionType.FETCH_PRODUCTS_ERROR, payload: 'Fetching error' })
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const deleteProduct = (guid: string, cb?: Function) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API_PRODUCTS}/${guid}`, { headers: { Authorization: `Bearer ${getJwt()}` } })
      throwSuccessMessage(`Success! ${guid} deleted!`)
      dispatch({ type: ProductActionType.DELETE_PRODUCT_SUCCESS, payload: { guid } })
      if (cb) {
        cb()
      }
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}
