import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { applyProductChanges, resetProduct } from '../../../store/actions/configurationActions'
import { showModal } from '../../../store/actions/modalActions'
import { ConfigurationsActionType } from '../../../types/configurations'
import { ModalTypes } from '../../../types/modals'
import { getLabel } from '../../../validators'
import style from './Styles/index.module.scss'

interface IConfigButtonsPanel {
  appName?: string
  title: string
  guid: string
}

const ConfigButtonsPanel: FC<IConfigButtonsPanel> = ({ appName, title, guid }) => {
  const dispatch = useDispatch()
  const { unsaved, productsConfigurations, valid } = useTypedSelector(state => state.configurations)
  const { productNameChangeCounter } = useTypedSelector(state => state.products)
  const validationKeys = Object.keys(valid[guid] || {})
  const [productName, setProductName] = useState<string>('')

  const getValidationResult = (): boolean => {
    for (let key in validationKeys) {
      if (Object.keys(valid[guid][validationKeys[key]]).length) {
        if (!valid[guid][validationKeys[key]].undefined) {
          return true
        }
      }
    }
    return false
  }

  const isProductValid: boolean = getValidationResult()

  const handleOnReset = (): void => {
    dispatch(
      showModal(ModalTypes.MAIN_MODAL, {
        body: `Reset all changes for ${appName}?`,
        onSubmit: () => dispatch(resetProduct(guid)),
      }),
    )
  }

  const handleOnApply = (): void => {
    dispatch({ type: ConfigurationsActionType.CHECK_INVALID_TABS, payload: { guid, data: 1 } })
    console.log(productsConfigurations[guid]?.configuration)
    if (!isProductValid) {
      dispatch(
        showModal(ModalTypes.MAIN_MODAL, {
          body: `Apply all changes for ${appName}?`,
          onSubmit: () => dispatch(applyProductChanges(guid, productsConfigurations[guid]?.configuration)),
        }),
      )
      dispatch({ type: ConfigurationsActionType.CHECK_INVALID_TABS, payload: { guid, data: false } })
    }
  }

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(title)
    setProductName(raw)
  }, []) // eslint-disable-line

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(title)
    setProductName(raw)
  }, [productNameChangeCounter]) // eslint-disable-line

  return (
    <div className="d-flex mb-2 align-items-center">
      <div>
        <h1 className={[style.title, 'app-color-text mb-0 text-capital word-break'].join(' ')}>{productName}</h1>
        <span className="text-secondary">App name: {appName}</span>
      </div>
      <button type="button" onClick={handleOnReset} disabled={!unsaved[guid || '']} className="app-btn-reset btn-primory app-btn-main ml-auto mr-2">
        <FormattedMessage id="reset" />
      </button>
      <button type="submit" onClick={handleOnApply} className="app-btn-main app-btn-apply ">
        <FormattedMessage id="apply" />
      </button>
    </div>
  )
}

export default React.memo(ConfigButtonsPanel)
