import React, { FC } from 'react'
import { Card, Tab } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../hooks/useFormValidation'
import { showModal } from '../../store/actions/modalActions'
import { deleteUser, modifyUser } from '../../store/actions/usersActions'
import { ModalTypes } from '../../types/modals'
import { validationErrors } from '../../utils/ValidationErrors'
import { buildControlsExt, checkboxInput, passwordInput } from '../inputs/controls'
import { UserDataProps } from './types'
import { getUserModel, getValidationSchema } from '../../utils/controller'
import { UserPermissions } from '../../entity/User'

const UserData: FC<UserDataProps> = ({ user, index, currentUserLogin }) => {
  const dispatch = useDispatch()
  const isCurrentUser: boolean = user.login === currentUserLogin
  const userLocal = getUserModel(user, isCurrentUser)
  const schema = getValidationSchema(isCurrentUser)
  const [inputState, setInputState, touched, setTouched, validationResult] = useFormValidation(userLocal, schema, 'newPassword', 'confirmPassword')
  const isPasswordShanged = touched.newPassword || touched.confirmPassword
  const IsAbleToConfirm: boolean = isPasswordShanged && !!Object.keys(validationResult).length

  const handleReset = (): void => {
    setInputState(userLocal)
    setTouched({})
  }

  const handleOnDelete = (): void => {
    dispatch(
      showModal(ModalTypes.MAIN_MODAL, {
        body: `Delete user ${user.login}`,
        onSubmit: () => dispatch(deleteUser(user, user.login)),
      }),
    )
  }

  const passwordInputs = buildControlsExt(
    [
      passwordInput({
        name: 'newPassword',
        label: 'New password',
        isColumn: true,
        className: 'mb-2 label-sm',
        errMsg: validationErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'Repeat password',
        isColumn: true,
        className: 'mb-2 label-sm',
        errMsg: validationErrors.adminControllerRepeatPassword(inputState),
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult,
  )

  const checkboxInputs = buildControlsExt(
    [
      checkboxInput(UserPermissions.CONFIGURATION_MANAGEMENT, '', 'Configuration management', 'ml-0 label-sm', true),
      checkboxInput(UserPermissions.USER_MANAGEMENT, '', 'User management', 'ml-0 label-sm', isCurrentUser),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult,
  )

  console.log(inputState.newPassword)

  const handleOnApply = (): void => {
    if (!isCurrentUser) {
      const user: any = {
        login: inputState.login,
        permissions: inputState.user_management
          ? [UserPermissions.CONFIGURATION_MANAGEMENT, UserPermissions.USER_MANAGEMENT]
          : [UserPermissions.CONFIGURATION_MANAGEMENT],
      }
      if (inputState.newPassword) {
        user.password = inputState.newPassword
      }
      handleReset()
      dispatch(modifyUser(user, index))
    } else {
      const user = {
        login: inputState.login,
        permissions: inputState.user_management
          ? [UserPermissions.CONFIGURATION_MANAGEMENT, UserPermissions.USER_MANAGEMENT]
          : [UserPermissions.CONFIGURATION_MANAGEMENT],
      }
      dispatch(modifyUser(user, index))
    }
  }

  const getTouched = (): boolean => {
    return JSON.stringify(inputState) === JSON.stringify(userLocal)
  }

  const isTouched = getTouched()

  if (isCurrentUser) return <></>

  return (
    <Tab.Pane eventKey={user.login} key={user.login}>
      <Card style={{ minWidth: '400px', maxWidth: '600px' }}>
        <Card.Header>
          <span className="h4 mt-1 mr-2">{user.login}</span>
        </Card.Header>
        <Card.Body>
          <Card.Text>{checkboxInputs}</Card.Text>
          <Card.Text>
            <p className="navi-blue mb-3 h5">Change password:</p>
            <div id="example-collapse-text">{passwordInputs}</div>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex">
          <button type="button" onClick={handleOnApply} disabled={isTouched || IsAbleToConfirm} className="app-btn-apply app-btn-main app-btn-large">
            Apply
          </button>
          <button type="button" disabled={isTouched} onClick={handleReset} className="app-btn-cancel app-btn-main app-btn-large ml-2">
            Cancel
          </button>
          {!isCurrentUser && (
            <button type="button" onClick={handleOnDelete} className="app-btn-delete app-btn-main app-btn-large ml-2">
              Delete user
            </button>
          )}
        </Card.Footer>
      </Card>
    </Tab.Pane>
  )
}

export default UserData
