import { UserPermissions } from '../entity/User'
import { getPermissions } from '../store/actions/authentication'
import { inputTypes } from '../validators/inputValidators/types'

export const getIsViewOnly = (restrictedAccess: boolean | undefined) => {
  return restrictedAccess && !getPermissions().includes(UserPermissions.CONFIGURATION_ADMINISTRATION)
}

export const getViewOnlyValue = (value: string, type?: string) => {
  if (type === inputTypes.SECRET && value) {
    return '******'
  }
  return String(value)
}
