const reg = /(yyyy|MM|dd|hh|mm|ss|fff)/g
const getTimeFormat = (timeSchema: string): string => timeSchema.toLowerCase().match(reg)?.join('/') || 'yyyy/mm/dd hh:mm:ss'
const getWithZero = (val: any): string => (Number(val) + 1 > 10 ? val : `0${val}`)

export const setTimeFormat = (timeSchema: string) => {
  const formatCheck = getTimeFormat(timeSchema)

  if (formatCheck.length > 20 && formatCheck.includes('fff')) {
    return 'fullDateMs'
  }
  if (formatCheck.length < 13 && formatCheck.includes('fff')) {
    return 'fullTime'
  }
  if (formatCheck.length < 9 && formatCheck.includes('hh')) {
    return 'time'
  }
  if (formatCheck.length < 14 && formatCheck.includes('yyyy')) {
    return 'date'
  }
  return 'fullDate'
}

export const transformDate = (timeFormat: string, date: Date) => {
  const current = new Date(date)
  const year = current.getFullYear()
  const month = Number(current.getMonth()) + 1 > 10 ? Number(current.getMonth()) + 1 : `0${Number(current.getMonth()) + 1}`
  const day = getWithZero(current.getDate())
  const hours = getWithZero(current.getHours())
  const minutes = getWithZero(current.getMinutes())
  const seconds = getWithZero(current.getSeconds())
  const fff = current.getMilliseconds()

  if (!date) return null

  switch (timeFormat) {
    case 'fullDateMs':
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${fff}`
    case 'fullTime':
      return `${hours}:${minutes}:${seconds}.${fff}`
    case 'time':
      return `${hours}:${minutes}:${seconds}`
    case 'date':
      return `${year}-${month}-${day}`
    default:
      return date
  }
}
