import { throwErrorMessage } from '../../utils/Errors'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { apiVersion } from '../../versions'
// import $api from './http'

const cookies = new Cookies()

export const saveJwt = (jwt: string): void => {
  cookies.set('jwt', jwt, { maxAge: 60 * 30 })
}

export const getJwt = (): string | null => cookies.get('jwt')
export const removeJwt = () => cookies.remove('jwt')
export const removeUser = () => localStorage.removeItem('user')

export const tokenRefresh = (refresh: string) => cookies.set('refresh', refresh)
export const getTokenRefresh = (): string | null => cookies.get('refresh')
export const removeTokenRefresh = () => cookies.remove('refresh')

export const signOut = (): void => {
  removeJwt()
  removeUser()
  removeTokenRefresh()
}

export const getUsername = (): string => {
  const user = localStorage.getItem('user')
  if (user === null) {
    return 'Unknown'
  }
  return JSON.parse(user).login || ''
}

export const getPermissions = (): string[] => {
  if (!localStorage.getItem('user')) return []
  const user = JSON.parse(localStorage.getItem('user') || '')

  const permissions = user?.permissions

  if (!user || !Array.isArray(permissions)) {
    return []
  }

  return permissions
}

export const saveUser = (data: any): void => {
  const user = JSON.parse(atob(data.accessToken.split('.')[1]))

  localStorage.setItem(
    'user',
    JSON.stringify({
      login: user.name,
      permissions: user.roles,
    }),
  )
}

export const fetchSignIn = async (login: string, password: string, onSuccess: any) => {
  try {
    const { data } = await axios.post(`${apiVersion}/authorize`, { login, password })
    saveJwt(data.accessToken)
    tokenRefresh(data.refreshToken)
    saveUser(data)
    onSuccess()
  } catch (e) {
    if (e.response.status === 404) {
      throwErrorMessage('User not found.')
      return
    }
    if (e.response.status === 403) {
      throwErrorMessage('Password or login incorrect.')
      return
    }
    throwErrorMessage(e.response?.data?.message, e.response?.status)
  }
}

const refreshAuth = async (failedRequest: any) => {
  const credentials = await axios.post(`${apiVersion}/refresh_token`, {
    refreshToken: getTokenRefresh(),
  })
  saveJwt(credentials.data.accessToken)
  tokenRefresh(credentials.data.refreshToken)
  saveUser(credentials.data)
  failedRequest.response.config.headers['Authorization'] = `Bearer ${credentials.data.accessToken}`
}

axios.interceptors.request.use(response => {
  response.headers['Authorization'] = `Bearer ${getJwt()}`
  return response
})

createAuthRefreshInterceptor(axios, refreshAuth)
