import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { IProduct } from '../../types/product'
import ProductCardHeader from './components/ProductCardHeader'
import ProductCardBody from './components/ProductCardBody'

interface IProductCard {
  product: IProduct
  className?: string
}

const ProductCard: FC<IProductCard> = ({ product, className }) => (
  <Card className={`m-2 h-auto ${className}`} style={{ width: '23rem' }} key={product.name}>
    <ProductCardHeader status={product.status} guid={product.guid} name={product.name} />
    <ProductCardBody guid={product.guid} name={product.name} path={product.path} version={product.version} status={product.status} />
  </Card>
)

export default ProductCard
