import React, { FC, useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import ErrMessageToltip from '../Errors/ErrMessageTooltip'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { IIntegerInput } from './types'

const IntegerInput: FC<IIntegerInput> = ({
  setState,
  setTouched,
  className,
  state,
  touched,
  errors,
  errorText,
  name,
  label,
  placeholder,
  isDisabled,
  tooltipText,
  isColumn,
  isLabelHiden,
  type,
  isRequired,
}) => {
  const [error, setError] = useState(false)
  const target = useRef(null)
  const isInvalid = touched && touched[name] && errors && errors[name]

  const handleChange = (event: any): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })

    setState({
      ...state,
      [name]: event.target.value,
    })

    if (!isNaN(+event.target.value.split(',').join(''))) {
      setError(false)
      return
    }
    setError(true)
  }

  const handleBlur = (): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })

    if (state[name] === '') {
      setError(false)
      return
    }
    setError(false)
  }

  useEffect(() => {
    if (state?.[name] === null) {
      setState({ ...state, [name]: '' })
    }
  }, [state?.[name]]) // eslint-disable-line

  const handleOnKeyPress = (evt: any) => {
    if (evt.which === 45) return
    if ((evt.which === 44 || evt.which === 46) && type !== 'integer') return
    if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
      evt.preventDefault()
    }
  }

  return (
    <Form.Group style={{ maxWidth: '600px' }} className={`${className} ${isInvalid && 'mb-4'} d-flex flex-1 m-1 align-items-center justify-content-between`}>
      {label && !isLabelHiden ? (
        <Form.Label style={{ maxWidth: isColumn ? '30%' : 'auto' }} className={`label-main mt-1 text-capital ${!isColumn && 'mr-2'}`}>
          <span className="label-main-wrapper p-relative">
            {label}: {isRequired && <span className="app-color-text label-required-mark">*</span>}
          </span>
        </Form.Label>
      ) : null}
      <span style={{ width: '70%' }} className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}>
        <span className="d-flex align-items-center flex-column w-100">
          <Form.Control
            className={`input-main ${tooltipText && 'input-padding'}`}
            type="string"
            placeholder={placeholder}
            value={state?.[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!isDisabled}
            isInvalid={isInvalid}
            ref={target}
            name={name}
            onKeyPress={handleOnKeyPress}
          />
          <Form.Control.Feedback className="input-feedback" type="invalid">
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </Form.Control.Feedback>
        </span>
        {tooltipText && <PromptBtn className="mt-1 input-tooltip" prompt={tooltipText ?? ''} />}
      </span>
      <ErrMessageToltip message={type === 'integer' ? 'int-only-toast' : 'num-only-toast'} error={error} target={target} />
    </Form.Group>
  )
}

export default IntegerInput
