import { ConfigurationsActionType, IConfigState } from '../../types/configurations'
import { cleanInvalidItems } from '../../utils/cleanInvalidItems'
import { blockActions } from '../actions/modelsActions/blockActions'
import { blockArrayActions } from '../actions/modelsActions/blockArrayActions'
import { blockDictionaryActions } from '../actions/modelsActions/blockDictionaryActions'
import { initEmptyDataAction } from '../actions/modelsActions/initEmptyData'
import { inputActions } from '../actions/modelsActions/inputActions'

const initialState: IConfigState = {
  productsConfigurations: {},
  productsConfigurationsDefault: {},
  unsaved: {},
  loading: false,
  fetchingError: false,
  valid: {},
  isChecked: {},
}

export const configurationsReducer = (state = initialState, action: { type: string; payload: any }): any => {
  const path = action?.payload?.path

  switch (action.type) {
    case ConfigurationsActionType.DISABLE_BLOCK:
      blockActions.disableBlock(state, path, action.payload.name)
      return { ...state, unsaved: { ...state.unsaved, [path[0]]: true } }

    case ConfigurationsActionType.ENABLE_BLOCK:
      blockActions.enableBlock(state, path, action.payload.name, action.payload.data)
      return { ...state, unsaved: { ...state.unsaved } }

    case ConfigurationsActionType.FETCH_CONFIGURATION:
      return { ...state, loading: true, fetchingError: false }

    case ConfigurationsActionType.FETCH_CONFIGURATION_ERROR:
      return { ...state, loading: false, fetchingError: true }

    case ConfigurationsActionType.FETCH_CONFIGURATION_SUCCESS:
      if (state.productsConfigurations[action.payload.guid]) {
        return { ...state, loading: false }
      }
      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [action.payload.guid]: action.payload.data,
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [action.payload.guid]: action.payload.data?.configuration,
        },
        unsaved: {
          ...state.unsaved,
          [action.payload.guid]: false,
        },
        valid: {
          [action.payload.guid]: {},
        },
        loading: false,
        fetchingError: false,
      }

    case ConfigurationsActionType.CHECK_INVALID_TABS:
      return {
        ...state,
        isChecked: {
          ...state.isChecked,
          [action.payload.guid]: state.isChecked[action.payload.guid] ? state.isChecked[action.payload.guid] + action.payload.data : 1,
        },
      }

    case ConfigurationsActionType.CONFIGURATION_RESET:
      return { ...state, loading: true }

    case ConfigurationsActionType.CONFIGURATION_RESET_SUCCESS:
      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [action.payload.guid]: action.payload.data,
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [action.payload.guid]: action.payload.data?.configuration,
        },
        unsaved: {
          ...state.unsaved,
          [action.payload.guid]: false,
        },
        loading: false,
        isChecked: false,
      }

    case ConfigurationsActionType.APPLY_CONFIGURATION:
      return { ...state, loading: true }

    case ConfigurationsActionType.APPLY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [action.payload.guid]: {
            ...state.productsConfigurations[action.payload.guid],
            configuration: action.payload.configuration,
          },
        },
        productsConfigurationsDefault: {
          ...state.productsConfigurationsDefault,
          [action.payload.guid]: action.payload.configuration,
        },
        unsaved: {
          ...state.unsaved,
          [action.payload.guid]: false,
        },
        loading: false,
      }

    case ConfigurationsActionType.UNSAVED_PRODUCT_SET:
      return {
        ...state,
        unsaved: { ...state.unsaved, [action.payload.guid]: action.payload.isUnsaved },
      }

    case ConfigurationsActionType.SET_INVALID_CONFIGURATION:
      return {
        ...state,
        valid: {
          [action.payload.guid]: {
            ...state.valid[action.payload.guid],
            [action.payload.tabName]: {
              ...state.valid[action.payload.guid]?.[action.payload.tabName],
              [action.payload.name]: true,
            },
          },
        },
      }

    case ConfigurationsActionType.SET_VALID_CONFIGURATION:
      delete state.valid?.[action.payload.guid]?.[action.payload.tabName]?.[action.payload.name]
      return {
        ...state,
        valid: {
          [action.payload.guid]: {
            ...state.valid[action.payload.guid],
          },
        },
      }

    case ConfigurationsActionType.BLOCK_DICTIONARY_ADD:
      blockDictionaryActions.addItem(state, path, action.payload.item, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_DICTIONARY_NEW_KEY:
      blockDictionaryActions.newKey(state, path, action.payload.data, action.payload.oldKey)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_DICTIONARY_DELETE:
      blockDictionaryActions.deleteItem(state, path, action.payload.item, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.FLAG_MODIFY:
      inputActions.flagModify(state, path, action.payload.componentState, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.INPUT_MODIFY:
      inputActions.inputModify(
        state,
        path,
        action.payload.componentState,
        action.payload.data,
        action.payload.name,
        action.payload?.type,
        action.payload?.isEmptyAsNull,
      )
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.MULTI_SELECT_MODIFY:
      inputActions.multiSelectModify(state, path, action.payload.componentState, action.payload.name, action.payload?.isNullable, action.payload?.isEmptyAsNull)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.DATE_TIME_MODIFY:
      inputActions.dateTimeModify(state, path, action.payload.componentState, action.payload.data, action.payload.name)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.ENUM_MODIFY:
      inputActions.enumModify(state, path, action.payload.componentState, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_DND:
      blockArrayActions.DND(state, path, action.payload.items, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_DELETE_ITEM:
      blockArrayActions.deleteItem(state, path, action.payload.index, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_CARD_DELETE_ITEM:
      blockArrayActions.deleteItemCard(state, path, action.payload.data)
      cleanInvalidItems(state.valid?.[path[0]]?.[path[2]], state, path, action.payload.cardItemToDelete)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_MODIFY:
      blockArrayActions.tableModify(state, path, action.payload.index, action.payload.data)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP:
      blockArrayActions.addItem(state, path, action.payload.data, 1, ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM:
      blockArrayActions.addItem(state, path, action.payload.data, 1, ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_ITEM:
      blockArrayActions.addItem(state, path, action.payload.data, 1, ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_ITEM)
      return {
        ...state,
        unsaved: { ...state.unsaved, [path[0]]: true },
      }

    case ConfigurationsActionType.EMPTY_CONFIG_INIT:
      return {
        ...state,
        productsConfigurations: {
          ...state.productsConfigurations,
          [action.payload.guid]: {
            ...state.productsConfigurations[action.payload.guid],
            configuration: {
              ...state.productsConfigurations[action.payload.guid].configuration,
              [action.payload.name]: action.payload.data,
            },
          },
        },
      }

    case ConfigurationsActionType.INIT_EMPTY_DATA:
      initEmptyDataAction(state, path, action.payload.name, action.payload.schema, action.payload?.type)
      return {
        ...state,
      }

    case ConfigurationsActionType.SET_TAB_VALID:
      delete state.valid?.[action.payload.guid]?.[action.payload.tabName]
      return {
        ...state,
        valid: {
          [action.payload.guid]: {
            ...state.valid[action.payload.guid],
          },
        },
      }

    default:
      return state
  }
}
