import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { buildControlsExt, textInput } from '../../inputs/controls'
import { validationErrors } from '../../../utils/ValidationErrors'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IStringInputProps } from './types'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { initInputData } from '../../../utils/InitInputData'

const StringInput: FC<IStringInputProps> = ({ data = {}, dataSchema, name, wrapper, dataPath }) => {
  const dispatch = useDispatch()
  const [path] = useState<string[]>([...(dataPath || []), name])
  const [checkPath, setCheckPath] = useState<string[]>([])
  const { isChecked } = useTypedSelector(state => state.configurations)
  const {
    is_required: isRequired,
    name: inputLabel,
    type,
    description: tooltipText,
    is_email: isEmail,
    default: defaultValue,
    is_empty_as_null: isEmptyAsNull,
  } = dataSchema
  const validationCheck = inputValidationCheck(type, dataSchema)
  const [inputState, setInputState, touched, setTouched, validationResult, isValid] = useFormValidation(data || {}, { [name]: validationCheck })
  const guid: string = path[0]
  const label: string = inputLabel || getLabel(name)
  const className: string = !wrapper ? 'w-50' : ''
  const errorKey: string = path.join('')
  const isDataEmpty: number = Object.keys(data || {}).length
  const isUndefined: boolean = typeof data?.[name] === 'undefined'
  const errMsg: string | undefined = validationErrors.stringInput(inputState?.[name], dataSchema)
  const isEmpty: boolean = !data || !isDataEmpty || isUndefined
  const isFieldReqired = isEmptyAsNull && isRequired

  useEffect(() => {
    if (dataPath?.length) {
      setCheckPath(dataPath)
      initInputData({ isEmpty, name, path, dataSchema, isRequired, guid, errorKey, data, setInputState }, dispatch)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isChecked[guid] && dataPath?.length) {
      isValid()
    }
  }, [isChecked, dataPath?.length]) // eslint-disable-line

  useEffect(() => {
    if (data && dataPath?.length) {
      setInputState(data)
    }
  }, [data, dataPath?.length]) // eslint-disable-line

  useEffect(() => {
    if (dataPath?.length) {
      inputDataDispatcher({ isEmpty, name, path, dataSchema, data, inputState, validationCheck, guid, errorKey, isUndefined, checkPath, type }, dispatch)
    }
  }, [inputState[name], touched[name], isChecked[guid]]) // eslint-disable-line

  const TextInput = buildControlsExt(
    [textInput({ name, tooltipText, label, className, isColumn: true, errMsg, isRequired: isFieldReqired, isEmail, placeholder: defaultValue })],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult,
  )

  return <div className={`${!wrapper ? 'w-100' : ''}`}>{TextInput}</div>
}

export default StringInput
