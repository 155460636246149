export const blockDictionaryActions = {
  addItem(state: any, path: string[], item: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state // в функцию
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (currentState[name] = { ...data, ...item })
    }

    if (currentPathLength < path.length) {
      path.forEach((key: any) => {
        if (typeof currentState?.[key] === 'object' && currentPathLength !== path.length) {
          this.addItem(currentState?.[key], path, item, data, (currentPathLength += 1))
        }
      })
    }
  },
  deleteItem(state: any, path: string[], item: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      delete data[item]
    }

    if (currentPathLength < path.length) {
      path.forEach((key: any) => {
        if (typeof currentState?.[key] === 'object') {
          this.deleteItem(currentState?.[key], path, item, data, (currentPathLength += 1))
        }
      })
    }
  },
  newKey(state: any, path: string[], data: any, oldKey: string, lenth = 1) {
    const newData = { ...data }
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      delete newData[oldKey]
      return (currentState[name] = newData)
    }

    if (currentPathLength !== path.length) {
      path.forEach((key: any) => {
        if (typeof currentState?.[key] === 'object') {
          this.newKey(currentState?.[key], path, data, oldKey, (currentPathLength += 1))
        }
      })
    }
  },
}
