import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { ITextInput } from './types'

const TextInput: React.FC<ITextInput> = props => {
  const {
    state,
    setState,
    touched,
    setTouched,
    errors,
    errorText,
    name,
    className,
    placeholder,
    label,
    isDisabled,
    tooltipText,
    isColumn,
    isLabelHiden,
    isRequired,
    isEmail,
  } = props
  const handleChange = (event: any): void => {
    setTouched && setTouched({ ...touched, [name]: true })
    setState({ ...state, [name]: event.target.value })
  }

  const handleBlur = (): void => {
    setTouched && setTouched({ ...touched, [name]: true })
  }

  useEffect(() => {
    if (state?.[name] === null) {
      setState({ ...state, [name]: '' })
    }
  }, [state?.[name]]) // eslint-disable-line

  const isInvalid = touched && touched[name] && errors && errors[name]

  return (
    <Form.Group style={{ maxWidth: '600px' }} className={`${className} ${isInvalid && 'mb-4'} d-flex flex-1 m-1 align-items-center justify-content-between`}>
      {label && !isLabelHiden ? (
        <Form.Label style={{ maxWidth: isColumn ? '30%' : 'auto' }} className={`label-main mt-1 text-capital ${!isColumn && 'mr-2'}`}>
          <span className="label-main-wrapper p-relative">
            {label}: {isRequired && <span className="app-color-text label-required-mark">*</span>}
          </span>
        </Form.Label>
      ) : null}

      <div style={{ width: '70%' }} className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}>
        <span className="d-flex align-items-center flex-column w-100">
          <Form.Control
            className={`input-main ${tooltipText && 'input-padding'}`}
            type={!isEmail ? 'text' : 'email'}
            placeholder={placeholder}
            value={state?.[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!isDisabled}
            isInvalid={isInvalid}
          />
          <Form.Control.Feedback className="input-feedback" type="invalid">
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </Form.Control.Feedback>
        </span>
        {tooltipText && <PromptBtn className="mt-1 input-tooltip" prompt={tooltipText ?? ''} />}
      </div>
    </Form.Group>
  )
}

export default React.memo(TextInput)
