export enum ModelTypes {
  CONFIG_FILE = 'config_file',
  BLOCK = 'block',
  BLOCK_ARRAY = 'block_array',
  BLOCK_DICTIONARY = 'block_dictionary',
  MT_CONNECTION = 'mt_connection',
  ADJUSTABLE_RANGE = 'adjustable_range',
  CONFIG_SETTINGS = 'config_settings',
  DB_CONNECTION = 'db_connection',
  MAIL_SENDER = 'mail_sender',
  STRING = 'string',
  INTEGER = 'integer',
  SECRET = 'secret',
  FLAG = 'flag',
  BOOL = 'bool',
  ENUM = 'enum',
  NUMBER = 'number',
  INT64 = 'int64',
  UINT64 = 'uint64',
  INT32 = 'int32',
  UINT32 = 'int32',
  INT16 = 'int16',
  INT16_PORT = 'int16_port',
  TIMEZONE = 'timezone',
  TIMEZONE_DIFERENCE = 'timezone_diference',
  DATETIME = 'datetime',
  TIME = 'time',
  DURATION = 'duration',
  DOUBLE = 'double',
  VERSION = 'version',
}

interface IInputSchema {
  type: string
  is_required: boolean
  name?: string
  is_empty_as_null?: boolean
  is_ignore_case?: boolean
  description?: string
  default?: string
  restricted_access?: boolean
}

export interface IStringInputSchema extends IInputSchema {
  min_size?: number | string
  max_size?: number | string
  is_email?: boolean
}

export interface INumberInputSchema extends IInputSchema {
  greater_than?: number | string
  greater_or_equal?: number | string
  less_or_equal?: number | string
  less_than?: number | string
  precision?: number
  format?: number | string
}
