import React, { FC, useMemo, useState } from 'react'
import { Card, Nav } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import style from '../Styles/index.module.scss'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ProductActionType } from '../../../types/product'
import { getLabel } from '../../../validators'
import AppButton from '../../buttons/AppButton/AppButton'
import ProductNameInput from '../../inputs/ProductNameInput'
interface IProductCardHeader {
  status: string
  guid: string
  name: string
}

const ProductCardHeader: FC<IProductCardHeader> = ({ status, guid, name }) => {
  const dispatch = useDispatch()
  const { productNameChangeCounter } = useTypedSelector(state => state.products)
  const [isProductNameEditing, setIsProductNameEditing] = useState<boolean>(false)
  const [productName, setProductName] = useState<string>('')
  const [productNameOld, setProductNameOld] = useState<string>('')
  const isProductActive: boolean = status === 'Active'

  const handleOnEdit = (): void => {
    setIsProductNameEditing(true)
  }

  const handleOnSave = (): void => {
    localStorage.setItem(`${guid}-productName`, productName)
    setIsProductNameEditing(false)
    setProductNameOld(productName)
    dispatch({ type: ProductActionType.PRODUCT_NAME_CHANGE })
  }

  const handleOnCancel = (): void => {
    setIsProductNameEditing(false)
    setProductName(productNameOld)
  }

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(name)
    setProductName(raw)
    setProductNameOld(raw)
  }, []) // eslint-disable-line

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(name)
    setProductName(raw)
  }, [productNameChangeCounter]) // eslint-disable-line

  return (
    <Card.Header className={style.header}>
      <Card.Title style={{ minHeight: '50px' }} className="text-capitalize d-flex align-items-center word-break fw-500">
        {!isProductNameEditing ? (
          <>
            <Nav.Link className="fw-500 w-90 p-0" disabled={!isProductActive}>
              <NavLink style={{ color: '#3CB5DC' }} className={`${!isProductActive && 'disabled-link'}`} to={guid}>
                {productName}
              </NavLink>
            </Nav.Link>
            <AppButton variant="edit" onClick={handleOnEdit} className="btn-edit navi-blue pr-0 icon-main" tooltip="Edit product name" />
          </>
        ) : (
          <>
            <ProductNameInput
              name={productName}
              setProductName={setProductName}
              handleOnSave={handleOnSave}
              handleOnCancel={handleOnCancel}
              feedbackClassName="ml-1"
            />
          </>
        )}
      </Card.Title>
    </Card.Header>
  )
}

export default ProductCardHeader
