import { inputTypes } from '../validators/inputValidators/types'

export const validationErrors = {
  number(state: any, schema: any) {
    if (schema.is_required && !state) return `error.field-required`
    if (String(state).includes('e')) return `error.numbers-only`
    if (typeof schema.greater_or_equal !== 'undefined' && Number(state) < schema.greater_or_equal) return `Greater or equal ${schema.greater_or_equal}`
    if (typeof schema.greater_than !== 'undefined' && Number(state) <= schema.greater_than) return `Greater than ${schema.greater_than}`
    if (typeof schema.less_or_equal !== 'undefined' && Number(state) > schema.less_or_equal) return `Less or equal ${schema.less_or_equal}`
    if (typeof schema.less_than !== 'undefined' && Number(state) >= schema.less_than) return `Less than ${schema.less_than}`
    if (state) return 'Invalid value'
  },
  stringInput(state: any, schema: any) {
    if (schema?.is_required && !state?.length) return `error.field-required`
    if (schema?.min_size && state?.length < schema?.min_size) return `${schema.min_size} characters minimum`
    if (schema?.max_size && state?.length > schema?.max_size) return `${schema.max_size} characters maximum`
    if (schema?.is_email) return 'Should be valid email address'
    if (schema?.regex) return schema.regex_error_message || `Value doesn't match`
    if (state) return 'Invalid value'
  },
  secretInput(state: any, schema: any) {
    if (schema?.min_size && state?.value?.length < schema?.min_size) return `${schema.min_size} characters minimum`
    if (schema?.max_size && state?.value?.length > schema?.max_size) return `${schema.max_size} characters maximum`
    if (schema?.is_required && !state?.value?.length) return `error.field-required`
  },
  multiSelect(state: any, schema: any) {
    if (schema?.is_required && !state?.length) return `error.field-required`
  },
  adminController(state: any) {
    if (state?.login?.length === 0) return `error.field-required`
    if (!state?.password?.length) return `error.field-required`
    if (state?.password?.length < 5) return `5 characters minimum`
  },
  adminControllerOldPassword(state: any) {
    if (!state?.oldPassword?.length) return `error.field-required`
    if (state?.oldPassword?.length < 5) return `5 characters minimum`
  },
  adminControllerNewPassword(state: any) {
    if (state?.newPassword?.length < 5) return `5 characters minimum`
    if (state?.newPassword === state?.oldPassword) return `New password is the same with current`
  },
  adminControllerRepeatPassword(state: any) {
    if (state?.confirmPassword?.length < 5) return `5 characters minimum`
    if (state?.confirmPassword !== state?.newPassword) return `Passwords don't match`
  },
  blockDictionaryMsg(state: any, schema: any) {
    if (!state?.length) return `error.field-required`
    if (schema.type === 'string') {
      return this.stringInput(state, schema)
    }
    if (schema.type === inputTypes.NUMBER || schema.type === inputTypes.INTEGER || schema.type === inputTypes.DOUBLE) {
      return this.number(state, schema)
    }
  },
}
