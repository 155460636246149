import { setConfigurationInValid, setConfigurationValid } from '../store/actions/configurationActions'
import { initEmptyData, inputActions } from '../store/actions/modelsActions/control'

interface IInputDataDispatcher {
  isEmpty: boolean
  name: string
  path: string[]
  dataSchema: any
  inputState: any
  validationCheck: any
  guid: string
  errorKey: string
  isUndefined: boolean
  data: any
  checkPath?: string[]
  type?: string
}

export function inputDataDispatcher(data: IInputDataDispatcher, dispatch: Function) {
  const { isEmpty, name, path, dataSchema, data: dataPrev, inputState, validationCheck, guid, errorKey, isUndefined, checkPath, type } = data
  const { is_empty_as_null: isEmptyAsNull } = dataSchema
  const tabName: string = path[2]

  if (path.length > 2) {
    if (isEmpty) {
      dispatch(initEmptyData(name, path, dataSchema))
    }
    if (dataPrev[name] !== inputState[name]) {
      dispatch(inputActions.modify(inputState, path, inputState, name, type, isEmptyAsNull))
    }
    if (!validationCheck?.isValidSync(inputState?.[name])) {
      dispatch(setConfigurationInValid(guid, tabName, errorKey))
    }
    if (validationCheck?.isValidSync(inputState?.[name]) && !isUndefined) {
      dispatch(setConfigurationValid(guid, tabName, errorKey))
    }
  } else {
    dispatch(setConfigurationValid(checkPath?.[0] || '', checkPath?.[2] || '', (checkPath || []).join('')))
  }
}
