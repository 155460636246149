import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { enumActions } from '../../../store/actions/modelsActions/control'
import { ConfigurationsActionType } from '../../../types/configurations'
import { getLabel } from '../../../validators'
import { buildControlsExt, selectInput } from '../../inputs/controls'
import { IEnumProps } from './types'

const Enum: FC<IEnumProps> = ({ data = {}, dataSchema = {}, name, wrapper, dataPath }) => {
  const dispatch = useDispatch()
  const [path] = useState<string[]>([...(dataPath || []), name])
  const [inputState, setInputState, touched, setTouched] = useFormValidation(data, {})
  const label: string = dataSchema.name || getLabel(name)
  const className: string = !wrapper ? 'w-50 pr-2' : ''
  const selected = Object.entries(dataSchema.options).find(option => option.find(el => el === data?.[name]))?.[0]

  useEffect(() => {
    if (data && dataPath?.length) {
      setInputState({ data, [name]: selected })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataPath?.length) {
      if (!data || !Object.keys(data).length || typeof data?.[name] === 'undefined') {
        dispatch({ type: ConfigurationsActionType.INIT_EMPTY_DATA, payload: { schema: dataSchema, name, path } })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(touched).length && dataPath?.length) {
      dispatch(enumActions.modify(inputState[name], path, inputState[name]))
    }
  }, [inputState]) // eslint-disable-line react-hooks/exhaustive-deps

  const EnumInput = buildControlsExt(
    [
      selectInput({
        name,
        options: Object.keys(dataSchema.options),
        selected: data?.[name] || dataSchema?.default || 0,
        tooltipText: dataSchema.description,
        label,
        className,
        isColumn: true,
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
  )

  return <div className={`${!wrapper ? 'w-100' : ''}`}>{EnumInput}</div>
}

export default Enum
