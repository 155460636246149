export const checkVersion = (version: string): boolean => {
  const versionRegEx = /^\d{1,2}\.\d{1,2}\.\d{1,2}$/
  return versionRegEx.test(version)
}

export const checkVersionsMatch = (productVersion: string, actualVersion: string): { major: boolean; minor: boolean; patch: boolean } => {
  return {
    major: productVersion?.split('.')?.[0] === actualVersion.split('.')?.[0],
    minor: productVersion?.split('.')?.[1] === actualVersion.split('.')?.[1],
    patch: productVersion?.split('.')?.[2] === actualVersion.split('.')?.[2],
  }
}
