import { integerInput, textInput, passwordInput, selectInput, checkboxInput, doubleInput } from '../inputs/controls'
import { getEmptyData } from '../../utils/InitData'
import { inputValidationCheck } from '../../validators/inputValidators/inputValidators'
import { validationErrors } from '../../utils/ValidationErrors'

export function inputCall(type: string, name: string, isLabelHiden: boolean, data?: any, schema?: any, secretSchema?: any, state?: any) {
  const elements: any = {
    integer: integerInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      errMsg: validationErrors.number(state[name], schema),
      validationType: data?.validationType,
      isRequired: schema?.isRequired,
    }),
    string: textInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      errMsg: validationErrors.stringInput(state[name], schema),
      isRequired: schema?.isRequired,
    }),
    password: passwordInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      errMsg: validationErrors.secretInput(state[name], schema),
      validationSchema: inputValidationCheck(secretSchema?.type, secretSchema),
      isRequired: schema?.isRequired,
    }),
    secret: passwordInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      errMsg: validationErrors.secretInput(state[name], schema),
      validationSchema: inputValidationCheck(secretSchema?.type, secretSchema),
      isRequired: schema?.isRequired,
    }),
    double: doubleInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      errMsg: validationErrors.number(state[name], schema),
      validationType: data?.validationType,
      precision: schema.precision || 2,
      isRequired: schema?.isRequired,
    }),
    enum: selectInput({ name, options: Object.keys(schema?.options || {}), selected: data?.selected, isColumn: data?.isColumn }),
    bool: checkboxInput(name, data?.tooltipText, data?.label, '', false, 'switch'),
    flag: checkboxInput(name, data?.tooltipText, data?.label, '', false, 'switch'),
    default: textInput({
      name,
      isLabelHiden,
      isColumn: data?.isColumn,
      tooltipText: data?.tooltipText,
      label: data?.label,
      isRequired: schema?.isRequired,
      errMsg: validationErrors.number(state[name], schema),
    }),
  }

  return elements?.[type] || elements.default
}

export const createEmptyDocument = (schema: any, name: string, data?: any) => {
  let init: any = data || {}

  if (schema?.children) {
    const keys = Object.keys(schema?.children || {})
    for (const item in keys) {
      const element = getEmptyData(schema?.children[keys[item]])
      init = { ...init, [keys[item]]: element }
    }
  }
  return init
}
