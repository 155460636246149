import React, { FC, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../hooks/useFormValidation'
import { modifyCurrentUser } from '../../store/actions/usersActions'
import { validationErrors } from '../../utils/ValidationErrors'
import { buildControlsExt, passwordInput } from '../inputs/controls'
import { getUserModel, getValidationSchema } from '../../utils/controller'
import { User } from '../../entity/User'
import { getLabel } from '../../validators'
import { getJwt } from '../../store/actions/authentication'

const Account: FC = () => {
  const dispatch = useDispatch()
  const user = new User()
  const [openUsers, setOpenUsers] = useState<boolean>(false)
  const userLocal = getUserModel(user, true)
  const schema = getValidationSchema(true)
  const [inputState, setInputState, touched, setTouched, validationResult] = useFormValidation(
    userLocal,
    schema,
    'newPassword',
    'confirmPassword',
    'oldPassword',
  )
  const IsAbleToConfirm: boolean = !!Object.keys(validationResult).length

  const handleReset = (): void => {
    setInputState(userLocal)
    setTouched({})
    setOpenUsers(false)
  }

  const passwordInputs = buildControlsExt(
    [
      passwordInput({
        name: 'oldPassword',
        label: 'Current password',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: validationErrors.adminControllerOldPassword(inputState),
      }),
      passwordInput({
        name: 'newPassword',
        label: 'New password',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: validationErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'Repeat password',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: validationErrors.adminControllerRepeatPassword(inputState),
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult,
  )

  const handleOnApply = (): void => {
    const user = {
      access_token: getJwt(),
      oldPassword: inputState.oldPassword,
      newPassword: inputState.newPassword,
    }
    handleReset()
    dispatch(modifyCurrentUser(user, '/current/change_password'))
  }

  const getTouched = (): boolean => {
    if (openUsers) return false
    return JSON.stringify(inputState) === JSON.stringify(userLocal)
  }

  const isTouched = getTouched()

  return (
    <div className="d-flex">
      <Card style={{ minWidth: '400px' }}>
        <Card.Header>
          <h3 className="app-color-text">{user.login}</h3>
        </Card.Header>
        <Card.Body>
          <Card.Title>
            <div className="d-flex flex-column border-bottom">
              <p className="navi-blue mb-3 h5">Permissions:</p>
              <div className="d-flex flex-column mb-3">
                {user.permissions.map((permission: string) => (
                  <span className="label-main d-flex align-items-center text-capitalize mb-1">
                    {getLabel(permission)} <input type="checkbox" className={`checkbox-lg ml-2`} disabled checked />
                  </span>
                ))}
              </div>
            </div>
          </Card.Title>
          <div>
            <p className="navi-blue mb-3 mt-3 h5">Change password:</p>
            <div id="example-collapse-text">{passwordInputs}</div>
          </div>
        </Card.Body>
        <Card.Footer className="mt-4">
          <Button onClick={handleOnApply} disabled={isTouched || IsAbleToConfirm} variant="primary">
            Apply
          </Button>
          <Button disabled={isTouched} onClick={handleReset} className="ml-2" variant="secondary">
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default Account
