import React, { FC } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'

const MainModal: FC = () => {
  const { modal } = useSelector((state: any) => state)
  const { data } = useTypedSelector(state => state.modal)
  const dispatch = useDispatch()

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnSubmit = (): void => {
    data.onSubmit()
    handleClose()
  }

  if (modal.modalType !== ModalTypes.MAIN_MODAL) return null

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{data.title ? data.title : <FormattedMessage id="please-confirm" />}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="blockquote m-0">{data.body}</div>
      </Modal.Body>
      <Form>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" type="submit" onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="primary" type="submit" onClick={handleOnSubmit}>
            <FormattedMessage id="confirm" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default MainModal
