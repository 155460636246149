export enum ModalTypes {
  ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT',
  CONFIRM_ALL_MODAL = 'CONFIRM_ALL_MODAL',
  ADD_NEW_USER_MODAL = 'ADD_NEW_USER_MODAL',
  MAIN_MODAL = 'MAIN_MODAL',
  LICENCE_MODAL = 'LICENCE_MODAL',
  PRODUCT_UNAVAILABLE_MODAL = 'PRODUCT_UNAVAILABLE_MODAL',
  ERROR_MODAL = 'ERROR_MODAL',
  INFORM_MODAL = 'INFORM_MODAL'
}

export enum ModalActionType {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}
