import React, { FC } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import { NavLink } from 'react-router-dom'
import { RoutesPaths } from '../../router'
import { fetchProduct } from '../../store/actions/configurationActions'

const ErrorModal: FC = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state: any) => state)
  const { data } = useTypedSelector(state => state.modal)

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnRetry = (): void => {
    dispatch(fetchProduct(data.guid))
    handleClose()
  }

  if (modal.modalType !== ModalTypes.ERROR_MODAL) return null

  return (
    <Modal show={true} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>{data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{data.message}</Modal.Body>
      <Form>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleOnRetry}>
            RETRY
          </Button>
          <NavLink to={RoutesPaths.SUMMARY} onClick={handleClose} className="nav-link" activeClassName="active">
            GO TO PRODUCTS
          </NavLink>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ErrorModal
