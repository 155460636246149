import { ModelTypes } from '../../../components/models/types'
import { IMultiSelectItem } from '../../../entity/MultiSelect'

export const inputActions = {
  inputModify(state: any, path: string[], item: any, data: any, name: string, type?: string, isEmptyAsNull?: boolean, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state

    if (currentPathLength === path.length) {
      if (item[name] === '' && type !== ModelTypes.SECRET) {
        return (state[name] = null)
      }
      return (state[name] = item[name])
    }

    if (currentPathLength < path.length) {
      this.inputModify(currentState?.[path[lenth - 1]], path, item, data, name, type, isEmptyAsNull, (currentPathLength += 1))
    }
  },
  dateTimeModify(state: any, path: string[], item: any, data: any, name: string, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      if (!item[name]) return (state[name] = null)
      return (state[name] = item[name])
    }

    if (currentPathLength < path.length) {
      this.dateTimeModify(currentState?.[path[lenth - 1]], path, item, data, name, (currentPathLength += 1))
    }
  },
  flagModify(state: any, path: string[], item: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name] = item)
    }

    if (currentPathLength < path.length) {
      this.flagModify(currentState?.[path[lenth - 1]], path, item, data, (currentPathLength += 1))
    }
  },
  enumModify(state: any, path: string[], item: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name] = item)
    }

    if (currentPathLength < path.length) {
      this.enumModify(currentState?.[path[lenth - 1]], path, item, data, (currentPathLength += 1))
    }
  },
  multiSelectModify(state: any, path: string[], componentState: any, name: string, isNullable?: boolean, isEmptyAsNull?: boolean, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const initial = componentState[name] || []

    if (currentPathLength === path.length && typeof state?.[name] !== 'undefined') {
      if (!componentState[name] && (isNullable || isEmptyAsNull)) {
        return (state[name] = null)
      } else {
        return (state[name] = initial.map((el: IMultiSelectItem) => el.value))
      }
    }

    if (currentPathLength < path.length) {
      this.multiSelectModify(currentState?.[path[lenth - 1]], path, componentState, name, isNullable, isEmptyAsNull, (currentPathLength += 1))
    }
  }
}
