import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { fetchProduct } from '../../../store/actions/configurationActions'
import { ProductActionType } from '../../../types/product'
import { getLabel } from '../../../validators'
import EditConfigBtn from '../../buttons/EditConfigBtn/EditConfigBtn'
import ProductNameInput from '../../inputs/ProductNameInput'
import style from '../Styles/index.module.scss'

interface IProductsDropdownItem {
  guid: string
  name: string
  status: string
  isPopower?: boolean
}

const ProductsDropdownItem: FC<IProductsDropdownItem> = ({ guid, name, status, isPopower }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { productsConfigurations, unsaved } = useTypedSelector(state => state.configurations)
  const { productNameChangeCounter } = useTypedSelector(state => state.products)
  const [isProductNameEditing, setIsProductNameEditing] = useState<boolean>(false)
  const [productName, setProductName] = useState<string>('')
  const [productNameOld, setProductNameOld] = useState<string>('')
  const [editConfigBtnShow, setEditConfigBtnShow] = useState(false)
  const isProductActive: boolean = status?.toLowerCase() === 'active'

  const handleOnRename = (): void => {
    setIsProductNameEditing(true)
  }

  const handleOnSave = (): void => {
    localStorage.setItem(`${guid}-productName`, productName)
    setIsProductNameEditing(false)
    setProductNameOld(productName)
    dispatch({ type: ProductActionType.PRODUCT_NAME_CHANGE })
  }

  const handleOnCancel = (): void => {
    setIsProductNameEditing(false)
    setProductName(productNameOld)
  }

  const handleOnClick = (): void => {
    if (isProductActive) {
      dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }
    if (!productsConfigurations[guid] && isProductActive) {
      dispatch(fetchProduct(guid))
    }
  }

  const onMouseOver = () => {
    setEditConfigBtnShow(true)
  }

  const onMouseLeave = () => {
    setEditConfigBtnShow(false)
  }

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(name)
    setProductName(raw)
    setProductNameOld(raw)
  }, []) // eslint-disable-line

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(name)
    setProductName(raw)
    setProductNameOld(raw)
  }, [productNameChangeCounter]) // eslint-disable-line

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={`d-flex align-items-center justify-content-between w-100 ${isPopower && 'pl-2 pt-1 pb-1 border-bottom'}`}
    >
      {isProductNameEditing ? (
        <ProductNameInput
          name={productName}
          setProductName={setProductName}
          handleOnSave={handleOnSave}
          handleOnCancel={handleOnCancel}
          buttonsClassName="app-border-color"
          feedbackClassName="text-center"
          size="sm"
        />
      ) : (
        <>
          <NavLink onClick={handleOnClick} to={isProductActive ? guid : location} className={`${!isProductActive && 'cursor-default'} dropdownLink`}>
            <span className={`${!isProductActive && 'inactive'} dropdown-label`}>
              <span className="p-relative">
                <span className={[style.dropdownProductLabel, !isProductActive && style.productInactive, 'text-capitalize word-break'].join(' ')}>{productName}</span>
                {unsaved[guid] && <FontAwesomeIcon className={style.editIndicator} icon={faEdit} />}
              </span>
            </span>
          </NavLink>
          <div className={`${!isPopower && 'app-w-50'} d-flex align-items-center p-relative`}>
            {editConfigBtnShow && !isPopower && <EditConfigBtn guid={guid} productName={name} handleOnRename={handleOnRename} />}
            <span style={{ background: isProductActive ? '#6FD1B9' : '#F48A8A' }} className={style.workStatusIndicator} />
          </div>
        </>
      )}
    </div>
  )
}

export default ProductsDropdownItem
