import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC, useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ProductStatus } from '../../entity/Product'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct, ProductActionType } from '../../types/product'
import CheckboxInput from '../inputs/CheckboxInput'
import Loader from '../Loader/Loader'
import ProductCard from '../ProductCard/ProductCard'

const Summary: FC = () => {
  const dispatch = useDispatch()
  const { products, isLoading } = useTypedSelector(state => state.products)
  const [checkBoxState, setCheckBoxState] = useState({ showActive: false })
  const inactiveProductsCheck = products.find((product: IProduct) => product.status === ProductStatus.INACTIVE)

  useEffect(() => {
    dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
  }, []) // eslint-disable-line

  if (isLoading && !products.length) return <Loader />

  return (
    <div className="flex-1">
      <OverlayScrollbarsComponent className="vh-full-header p-4">
        <h3 className="app-color-text ml-2">My Products:</h3>
        {inactiveProductsCheck && (
          <CheckboxInput className="ml-2" state={checkBoxState} name="showActive" label="Hide inactive" setState={setCheckBoxState} checkboxType="small" />
        )}
        {!products.length && (
          <Nav.Item>
            <div style={{ opacity: '0.5' }} className="h4 text-secondary vh-full d-flex align-items-center justify-content-center">
              No data
            </div>
          </Nav.Item>
        )}
        <div className="d-flex flex-wrap align-items-start">
          {!checkBoxState.showActive
            ? products.map((product: IProduct) => <ProductCard product={product} />)
            : products.filter((product: IProduct) => product.status === ProductStatus.ACTIVE).map((product: IProduct) => <ProductCard product={product} />)}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  )
}

export default Summary
