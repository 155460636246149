import { faBoxesStacked, faUserGear, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Account from '../components/admin/Account'
import Controller from '../components/admin/Controller'
import NotEnoughPrivilege from '../components/NotEnoughPrivilege'
import Summary from '../components/Summary/Summary'
import { UserPermissions } from '../entity/User'

export interface IRoute {
  name: string
  path: string
  component: JSX.Element
  exact: boolean
  permission: string
  icon?: JSX.Element
  dropdown?: any
}

export enum RoutesPaths {
  SUMMARY = '/summary',
  USERS = '/users',
  ACCOUNT = '/account',
  NOT_ENOUGH_PRIVILEGE = '/not-enough-privilege',
  REFRESH_TOKEN = '/api/v1/refresh_token',
}

export const routes: IRoute[] = [
  {
    name: 'Products',
    path: RoutesPaths.SUMMARY,
    component: <Summary />,
    exact: true,
    permission: UserPermissions.CONFIGURATION_MANAGEMENT,
    icon: <FontAwesomeIcon icon={faBoxesStacked} size="2x" />,
    dropdown: true,
  },
  {
    name: 'Users',
    path: RoutesPaths.USERS,
    component: <Controller />,
    exact: true,
    permission: UserPermissions.USER_MANAGEMENT,
    icon: <FontAwesomeIcon icon={faUserGroup} size="2x" />,
  },
  {
    name: 'Account',
    path: RoutesPaths.ACCOUNT,
    component: <Account />,
    exact: true,
    permission: UserPermissions.CONFIGURATION_MANAGEMENT,
    icon: <FontAwesomeIcon icon={faUserGear} size="2x" />,
  },
  {
    name: 'NotEnoughPrivilege',
    path: RoutesPaths.NOT_ENOUGH_PRIVILEGE,
    component: <NotEnoughPrivilege />,
    exact: true,
    permission: '_',
  },
]
