import React, { FC } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'

const ConfirmAllModal: FC = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state: any) => state)

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  if (modal.modalType !== ModalTypes.CONFIRM_ALL_MODAL) return null

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={modal.data?.modalTitle ?? 'please-confirm'} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="h6">
            <FormattedMessage id={modal.data?.modalBody ?? ''} />
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" type="submit" onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              handleClose()
              modal.data?.actionOnConfirm()
            }}
          >
            <FormattedMessage id="confirm" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmAllModal
