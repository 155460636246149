export enum ProductActionType {
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR',
  PRODUCT_NAME_CHANGE = 'PRODUCT_NAME_CHANGE',
  SET_PRODUCT_ROUTED = 'SET_PRODUCT_ROUTED'
}

export interface IProduct {
  status: string
  guid: string
  name: string
  version: string
  path: string
  ip: string
}
