import React from 'react'
import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'

const bottomRightToastPosition = { position: toast.POSITION.BOTTOM_RIGHT }

export const throwErrorMessageWithoutAutoClose = (message: string = 'error') => {
  toast.error(<div className="error-toast">{message}</div>, { autoClose: false, closeOnClick: false })
}

export const throwErrorMessage = (message: string, status?: number | string): void => {
  if (status === 503 || status === 401) {
    return
  }
  if (message) {
    toast.error(<div className="error-toast">{message}</div>, { closeOnClick: false })
    return
  }
  if (status === 400 && !message) {
    toast.error(<FormattedMessage id="error.400" tagName="span" />)
    return
  }
  if (status === 403 && !message) {
    toast.error(<FormattedMessage id="error.403" tagName="span" />)
    return
  }
  if (status === 404 && !message) {
    toast.error(<FormattedMessage id="error.404" tagName="span" />)
    return
  }
  if (status === 409 && !message) {
    toast.error(<FormattedMessage id="error.409" tagName="span" />)
    return
  }
  if (status === 429 && !message) {
    toast.error(<FormattedMessage id="error.429" tagName="span" />)
    return
  }
  if (status === 500 && !message) {
    toast.error(<FormattedMessage id="error.500" tagName="span" />)
    return
  }
  if (status === 502 && !message) {
    toast.error(<FormattedMessage id="error.502" tagName="span" />)
    return
  }
  toast.error(<FormattedMessage id={'Server error'} tagName="span" />)
}

export const throwSuccessMessage = (message: any, values?: any): void => {
  // добавить типы
  if (values) {
    toast.success(<span>{message}</span>, bottomRightToastPosition)
  } else {
    toast.success(<span>{message}</span>, bottomRightToastPosition)
  }
}
