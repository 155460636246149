import * as React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import { getLabel } from '../../validators'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { IDateTimePicker } from './types'

const DateTimePicker: React.FC<IDateTimePicker> = ({
  minDate,
  maxDate,
  state,
  valueKey,
  setState,
  messageKey,
  disabled,
  className,
  timeIntervals,
  timeFormat,
  description,
  isRequired,
}) => {
  const intl = useIntl()

  const onChange = (date: Date): void => {
    setState({ ...state, [valueKey]: date })
  }

  switch (timeFormat) {
    case 'fullDateMs':
      return (
        <Form.Group style={{ maxWidth: '600px' }} className={`${className} d-flex flex-1 m-1 align-items-center p-relative`}>
          {messageKey ? (
            <Form.Label style={{ width: '30%' }} className={`label-main mt-1 text-capital`}>
              {getLabel(messageKey)}: {isRequired && <span className="app-color-text">*</span>}
            </Form.Label>
          ) : null}
          <DatePicker
            locale={intl.locale}
            disabled={disabled ?? false}
            className="form-control ml-2 fullDatePicker-ms"
            selected={state?.[valueKey] ? new Date(state[valueKey]) : new Date()}
            onChange={onChange}
            timeCaption="time"
            dateFormat="yyyy/MM/dd hh:mm:ss.SSS"
            timeFormat="HH:mm:ss"
            showTimeSelect
            timeIntervals={timeIntervals}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
          />
        </Form.Group>
      )
    case 'fullTime':
      return (
        <Form.Group style={{ maxWidth: '600px' }} className={`${className} d-flex flex-1 m-1 align-items-center`}>
          {messageKey ? (
            <Form.Label style={{ width: '30%' }} className={`label-main mt-1 text-capital`}>
              <span className="label-main-wrapper p-relative">
                {getLabel(messageKey)}: {isRequired && <span className="app-color-text label-required-mark">*</span>}
              </span>
            </Form.Label>
          ) : null}
          <DatePicker
            locale={intl.locale}
            disabled={disabled ?? false}
            className="form-control ml-2 timeOnlyPicker-ms"
            selected={state?.[valueKey] ? new Date(state[valueKey]) : new Date()}
            onChange={onChange}
            timeCaption="time"
            dateFormat="hh:mm:ss.SSS"
            timeFormat="HH:mm:ss.SSS"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
          />
          {description && <PromptBtn prompt={description ?? ''} />}
        </Form.Group>
      )
    case 'time':
      return (
        <Form.Group style={{ maxWidth: '600px' }} className={`${className} d-flex flex-1 m-1 align-items-center `}>
          {messageKey ? (
            <Form.Label style={{ width: '30%' }} className={`label-main mt-1 text-capital`}>
              {getLabel(messageKey)}: {isRequired && <span className="app-color-text">*</span>}
            </Form.Label>
          ) : null}
          <DatePicker
            locale={intl.locale}
            disabled={disabled ?? false}
            className="form-control ml-2 timeOnlyPicker"
            selected={state?.[valueKey] ? new Date(state[valueKey]) : new Date()}
            onChange={onChange}
            timeCaption="time"
            dateFormat="hh:mm:ss"
            timeFormat="HH:mm:ss"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
          />
          {description && <PromptBtn prompt={description ?? ''} />}
        </Form.Group>
      )

    case 'date':
      return (
        <Form.Group style={{ maxWidth: '600px' }} className={`${className} d-flex flex-1 m-1 align-items-center `}>
          {messageKey ? (
            <Form.Label style={{ width: '30%' }} className={`label-main mt-1 text-capital`}>
              {getLabel(messageKey)}: {isRequired && <span className="app-color-text">*</span>}
            </Form.Label>
          ) : null}
          <DatePicker
            disabled={disabled ?? false}
            className="form-control datePicker test-test"
            selected={state?.[valueKey] ? new Date(state[valueKey]) : null}
            onChange={onChange}
            dateFormat="yyyy/MM/dd"
            timeIntervals={timeIntervals}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
          />
          {description && <PromptBtn prompt={description ?? ''} />}
        </Form.Group>
      )

    default:
      return (
        <Form.Group style={{ maxWidth: '600px' }} className={`${className} d-flex flex-1 m-1 align-items-center `}>
          {messageKey ? (
            <Form.Label style={{ width: '30%' }} className={`label-main mt-1 text-capital`}>
              {getLabel(messageKey)}: {isRequired && <span className="app-color-text">*</span>}
            </Form.Label>
          ) : null}
          <DatePicker
            locale={intl.locale}
            disabled={disabled ?? false}
            className="form-control ml-2 fullDatePicker"
            selected={state?.[valueKey] ? new Date(state[valueKey]) : new Date()}
            onChange={onChange}
            timeCaption="time"
            dateFormat="yyyy/MM/dd hh:mm:ss"
            timeFormat="HH:mm:ss"
            showTimeSelect
            timeIntervals={timeIntervals}
            minDate={minDate ? minDate : undefined}
            maxDate={maxDate ? maxDate : undefined}
          />
          {description && <PromptBtn prompt={description ?? ''} />}
        </Form.Group>
      )
  }
}

export default DateTimePicker
