import { useState } from 'react'

export function useFormValidation(item: any, schema: any, name?: string, confirmationName?: string, currentName?: string) {
  const [inputState, setInputState] = useState<any>(item)
  const [touched, setTouched] = useState<any>({})

  const validate = (object: any, schema: any) => {
    const errors: { [index: string]: any } = {}

    if (name && confirmationName) {
      if (object?.[name] !== object?.[confirmationName]) {
        errors[confirmationName] = true
      }
    }

    if (name && currentName) {
      if (object?.[name] === object?.[currentName]) {
        errors[name] = true
      }
    }

    for (const field in schema) {
      if (name === 'secretType') {
        if (!schema[field].isValidSync(object?.[field]?.value)) {
          errors[field] = true
        }
      }

      if (!schema[field].isValidSync(object?.[field])) {
        errors[field] = true
      }
    }
    return errors
  }

  const validationResult = validate(inputState, schema)

  const isValid = () => {
    if (Object.values(validationResult).find((result: boolean) => result)) {
      setTouched(Object.fromEntries(Object.entries(inputState || {}).map(([key]) => [key, true])))
      return false
    }
    return true
  }

  return [inputState, setInputState, touched, setTouched, validationResult, isValid]
}
