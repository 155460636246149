import React, { FC } from 'react'
import style from '../Styles/index.module.scss'
import { IProduct } from '../../../types/product'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ProductsDropdownItem from './ProductsDropdownItem'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Options } from 'overlayscrollbars'

interface IProductsDropdownList {
  isPopower?: boolean
}

const ProductsDropdownList: FC<IProductsDropdownList> = ({ isPopower }) => {
  const { products, isProductRouted } = useTypedSelector(state => state.products)
  const { unsaved } = useTypedSelector(state => state.configurations)
  const overflowBehaviorY = products.length <= 5 ? 'visible-hidden' : 'scroll'

  return (
    <OverlayScrollbarsComponent
      style={{ maxHeight: '400px', width: '100%', background: '#397B8E', borderRadius: isPopower ? '8px' : '0' }}
      options={{ overflowBehavior: { x: 'visible-hidden', y: overflowBehaviorY } } as Options}
    >
      <div
        style={{ background: isProductRouted ? '#397B8E' : 'transparent' }}
        className={[!isPopower ? style.dropdownContainer : style.dropdownContainerPopover].join(' ')}
      >
        {!isPopower && <div className={style.dropdownBorder} />}
        <div className="w-100">
          {products.map((product: IProduct) => (
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip style={{ zIndex: 100 }} id="button-tooltip">
                  <div>{product.name}</div>
                  {unsaved[product.guid] && <div>(unsaved changes)</div>}
                </Tooltip>
              }
            >
              <div className={style.dropdownProduct} key={product.guid}>
                <ProductsDropdownItem isPopower={isPopower} guid={product.guid} name={product.name} status={product.status} />
              </div>
            </OverlayTrigger>
          ))}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  )
}

export default ProductsDropdownList
