import React, { FC } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'

const InformModal: FC = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state: any) => state)
  const { data } = useTypedSelector(state => state.modal)

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  if (modal.modalType !== ModalTypes.INFORM_MODAL) return null

  return (
    <Modal backdrop="static" show={true}>
      {data.title && (
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id={data.title} tagName="span" />
          </Modal.Title>
        </Modal.Header>
      )}
      {data.message && (
        <Modal.Body>
          <FormattedMessage id={data.message} tagName="p" values={data.values} />
        </Modal.Body>
      )}
      <Form>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default InformModal
