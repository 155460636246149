import React, { FC } from 'react'
import * as yup from 'yup'
import { Modal, Button, Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideModal } from '../../store/actions/modalActions'
import { createNewUser } from '../../store/actions/usersActions'
import { ModalTypes } from '../../types/modals'
import { CreateUser } from '../../entity/Users'
import { validationErrors } from '../../utils/ValidationErrors'
import { passwordInput, textInput, buildControlsExt, checkboxInput } from '../inputs/controls'
import { IUser } from '../../types/user'
import { getUserModel } from '../../utils/controller'
import { UserPermissions } from '../../entity/User'

const schema = {
  login: yup.string().required(),
  newPassword: yup.string().min(5).required(),
  confirmPassword: yup.string().min(5).required(),
}

const AddNewUserModal: FC = () => {
  const { modal } = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const newUser = new CreateUser()
  const newLocal = getUserModel(newUser, false)
  const [inputState, setInputState, touched, setTouched, validationResult] = useFormValidation(newLocal, schema, 'newPassword', 'confirmPassword')
  const addUserForm = buildControlsExt(
    [
      textInput({
        name: 'login',
        label: 'Username',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        errMsg: validationErrors.adminController(inputState),
      }),
      passwordInput({
        name: 'newPassword',
        label: 'Password',
        isColumn: true,
        isRequired: true,
        className: 'mb-3',
        inputType: 'password',
        errMsg: validationErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'Repeat password',
        isColumn: true,
        className: 'mb-3',
        isRequired: true,
        inputType: 'password',
        errMsg: validationErrors.adminControllerRepeatPassword(inputState),
      }),
      checkboxInput(UserPermissions.CONFIGURATION_MANAGEMENT, '', 'Configuration management', 'ml-0', true),
      checkboxInput(UserPermissions.USER_MANAGEMENT, '', 'User management', 'ml-0'),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult,
  )

  const handleOnSubmit = (): void => {
    const permissions = inputState.user_management
      ? [UserPermissions.CONFIGURATION_MANAGEMENT, UserPermissions.USER_MANAGEMENT]
      : [UserPermissions.CONFIGURATION_MANAGEMENT]
    const user: IUser = {
      login: inputState.login,
      password: inputState.confirmPassword,
      permissions,
    }
    dispatch(createNewUser(user, setInputState, newLocal, setTouched))
  }

  const handleClose = (): void => {
    dispatch(hideModal())
    setInputState(newLocal)
    setTouched({})
  }

  const IsAbleToConfirm = !!Object.keys(validationResult).length

  if (modal.modalType !== ModalTypes.ADD_NEW_USER_MODAL) return null

  return (
    <Modal id="add-user-modal" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="add-new-user" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="blockquote m-0">{addUserForm}</div>
      </Modal.Body>
      <Form>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" type="button" onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="primary" type="button" disabled={IsAbleToConfirm} onClick={handleOnSubmit}>
            <FormattedMessage id="confirm" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddNewUserModal
