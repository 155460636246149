import React, { FC } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import { actualSchemaVersion } from '../../versions'

const uiVersion = '1.0.1.2'

const LicenceModal: FC = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state: any) => state)

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnSubmit = (): void => {
    handleClose()
  }

  if (modal.modalType !== ModalTypes.LICENCE_MODAL) return null

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>TFB Web configurator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>UI version {uiVersion}</span>
        <br />
        <span>Schema version {actualSchemaVersion}</span>
        <br />
        <span>© 2022 - Tools For Brokers PTE LTD</span>
        <br />
      </Modal.Body>
      <Form>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleOnSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default LicenceModal
