import axios from 'axios'
import { Dispatch } from 'redux'
import { ConfigurationsActionType } from '../../types/configurations'
import { throwErrorMessage, throwErrorMessageWithoutAutoClose, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { getJwt } from './authentication'

const API = `${apiVersion}/schema_and_configuration`

export const fetchProduct = (guid: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION })
      const { data } = await axios.get(`${API}/${guid}`, { headers: { Authorization: `Bearer ${getJwt()}` } })
      dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION_SUCCESS, payload: { guid, data } })
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
      dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR, payload: 'Fetching error' })
    }
  }
}

export const resetProduct = (guid: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ConfigurationsActionType.CONFIGURATION_RESET })
      const { data } = await axios.get(`${API}/${guid}`, { headers: { Authorization: `Bearer ${getJwt()}` } })
      dispatch({ type: ConfigurationsActionType.CONFIGURATION_RESET_SUCCESS, payload: { guid, data } })
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
      dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR, payload: 'Fetching error' })
    }
  }
}

export const applyProductChanges = (guid: string, configuration: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(`/api/configuration/${guid}`, { configuration: configuration }, { headers: { Authorization: `Bearer ${getJwt()}` } })
      throwSuccessMessage(`Success!`)
      dispatch({ type: ConfigurationsActionType.APPLY_CONFIGURATION_SUCCESS, payload: { configuration, guid } })
      dispatch(fetchProduct(guid))
    } catch (e) {
      throwErrorMessageWithoutAutoClose(e.response?.data?.message || e.response?.status)
    }
  }
}

export const setConfigurationInValid = (guid: string, tabName: string, name?: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: ConfigurationsActionType.SET_INVALID_CONFIGURATION, payload: { guid, name, tabName } })
  }
}

export const setConfigurationValid = (guid: string, tabName: string, name?: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: ConfigurationsActionType.SET_VALID_CONFIGURATION, payload: { guid, name, tabName } })
  }
}
