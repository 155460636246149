import axios from 'axios'
import { Dispatch } from 'redux'
import { UsersActionTypes } from '../../types/users'
import { throwErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { getJwt } from './authentication'
import { hideModal } from './modalActions'

const API_USERS = `${apiVersion}/users`

export const fetchUsers = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: UsersActionTypes.FETCH_USERS })
      const { data } = await axios.get(API_USERS, { headers: { Authorization: `Bearer ${getJwt()}` } })
      dispatch({ type: UsersActionTypes.FETCH_USERS_SUCCESS, payload: data })
    } catch (e) {
      dispatch({ type: UsersActionTypes.FETCH_USERS_ERROR, payload: 'Fetching error' })
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const deleteUser = (user: any, login: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API_USERS}`, { headers: { Authorization: `Bearer ${getJwt()}`, login } })
      throwSuccessMessage(`Success! ${user.login} deleted!`)
      dispatch({ type: UsersActionTypes.DELETE_USER_SUCCESS, payload: { login } })
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const modifyUser = (user: any, index?: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.put(`${API_USERS}`, user, { headers: { Authorization: `Bearer ${getJwt()}` } })
      throwSuccessMessage(`Success!`)
      dispatch({ type: UsersActionTypes.MODIFY_USER_SUCCESS, payload: { user, index } })
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const modifyCurrentUser = (data: any, path: string) => {
  return async () => {
    try {
      await axios.post(`${API_USERS}${path}`, data, { headers: { Authorization: `Bearer ${getJwt()}` } })
      throwSuccessMessage(`Success!`)
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const createNewUser = (user: any, setState: any, initialState: any, setTouched: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(`${API_USERS}`, user, { headers: { Authorization: `Bearer ${getJwt()}` } })
      throwSuccessMessage(`Success!`)
      dispatch({ type: UsersActionTypes.ADD_NEW_USER_SUCCESS, payload: { user } })
      dispatch(hideModal())
      setState(initialState)
      setTouched({})
    } catch (e) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}
