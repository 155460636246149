import { RightBarActionType } from '../../types/rightbar'

class RightBarState {
  public rightBarHidden: boolean
  public name: string
  public data: any

  constructor(rightBarHidden: boolean = true, name: string = '', data: any = []) {
    this.rightBarHidden = rightBarHidden
    this.name = name
    this.data = data
  }
}

export function rightBarReducer(state = new RightBarState(), action: any) {
  switch (action.type) {
    case RightBarActionType.SHOW_RIGHT_BAR:
      return {
        ...state,
        rightBarHidden: false,
        rightBarItem: action.payload.rightBarItem,
      }
    case RightBarActionType.FULL_SCREEN:
      return {
        ...state,
        fullScreen: true,
      }
    case RightBarActionType.COLLAPSE:
      return {
        ...state,
        fullScreen: false,
      }
    case RightBarActionType.HIDE_RIGHT_BAR:
      return new RightBarState()
  }
  return state
}
