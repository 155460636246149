import { getEmptyData } from '../../../utils/InitData'
import { inputTypes } from '../../../validators/inputValidators/types'

export const initEmptyDataAction = (state: any, path: string[], name: string, schema: any, type?: string, lenth = 1) => {
  let currentPathLength: number = lenth
  const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state

  if (currentPathLength === path.length) {
    if (!state && schema.type !== inputTypes.BLOCK_ARRAY) {
      state = {}
    }
    if (!state && schema.type === inputTypes.BLOCK_ARRAY) {
      state = []
    }
    return (state[name] = getEmptyData(schema))
  }

  if (currentPathLength < path.length) {
    initEmptyDataAction(currentState?.[path[lenth - 1]], path, name, schema, type, currentPathLength + 1)
  }
}
