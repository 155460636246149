import React, { FC, useState, useRef } from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faExclamation } from '@fortawesome/free-solid-svg-icons'
import style from './Styles/index.module.scss'

interface PromptBtnProps {
  prompt: string
  size?: string
  className?: string
  type?: string
}

const PromptBtn: FC<PromptBtnProps> = ({ prompt, className, size, type }) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const handleOnMouseEnter = (): void => {
    setShow(true)
  }
  const handleOnMouseLeave = (): void => {
    setShow(false)
  }

  const getPrompt = (): JSX.Element => {
    if (type === 'validation') {
      return (
        <>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            className={[style.smallButton, 'ml-2 pointer text-danger border-danger border', className].join(' ')}
            ref={target}
          >
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          <Overlay target={target.current} show={show} placement="top">
            <div
              className="p-2"
              id="popover-prompt"
              style={{
                backgroundColor: 'rgba(255, 100, 100, 0.85)',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                zIndex: 100000,
              }}
            >
              <span className={style.prompt}>{prompt}</span>
            </div>
          </Overlay>
        </>
      )
    }
    return (
      <>
        <span onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={[style.button, 'ml-2 pointer', className].join(' ')} ref={target}>
          <FontAwesomeIcon icon={faQuestion} />
        </span>
        <Overlay target={target.current} show={show} placement="top">
          <Popover className="p-2" id="popover-prompt">
            <span className={style.prompt}>{prompt}</span>
          </Popover>
        </Overlay>
      </>
    )
  }

  switch (size) {
    case 'small':
      return (
        <>
          <span onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={`${className} pointer`} ref={target}>
            <FontAwesomeIcon icon={faQuestion} />
          </span>
          <Overlay target={target.current} show={show} placement="top">
            <Popover className="p-2" id="popover-prompt">
              <span className={style.prompt}>{prompt}</span>
            </Popover>
          </Overlay>
        </>
      )

    default:
      return <>{getPrompt()}</>
  }
}

export default React.memo(PromptBtn)
