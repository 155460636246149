import { inputTypes } from './types'
import * as yup from 'yup'

export function inputValidationCheck(type: string, schema?: any, value?: any) {
  let inputString: any = yup.string()
  let blockArray: any = yup.array()

  switch (type) {
    case inputTypes.INTEGER:
      if (typeof schema.greater_or_equal !== 'undefined') {
        inputString = inputString.test('num', 'Must be greater or equal', (val: any) => {
          if (!isNaN(val) && !Number.isInteger(Number(val))) {
            return false
          }
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && !isNaN(val) && Number.isInteger(Number(val))) {
            return BigInt(val) >= BigInt(schema.greater_or_equal)
          }
          return Number(val) >= Number(schema.greater_or_equal)
        })
      }
      if (typeof schema.greater_than !== 'undefined') {
        inputString = inputString.test('num', 'Must be greater than', (val: any) => {
          if (!isNaN(val) && !Number.isInteger(Number(val))) {
            return false
          }
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && !isNaN(val) && Number.isInteger(Number(val))) {
            return BigInt(val) > BigInt(schema.greater_than)
          }
          return Number(val) > Number(schema.greater_than)
        })
      }
      if (typeof schema.less_or_equal !== 'undefined') {
        inputString = inputString.test('num', 'Must be less or equal', (val: any) => {
          if (!isNaN(val) && !Number.isInteger(Number(val))) {
            return false
          }
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && !isNaN(val) && Number.isInteger(Number(val))) {
            return BigInt(val) <= BigInt(schema.less_or_equal)
          }
          return Number(val) <= Number(schema.less_or_equal)
        })
      }
      if (typeof schema.less_than !== 'undefined') {
        inputString = inputString.test('num', 'Must be less than', (val: any) => {
          if (!isNaN(val) && !Number.isInteger(Number(val))) {
            return false
          }
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && !isNaN(val) && Number.isInteger(Number(val))) {
            return BigInt(val) < BigInt(schema.less_than)
          }
          return Number(val) < Number(schema.less_than)
        })
      }
      if (schema.is_required) {
        inputString = inputString.matches(/^-?[0-9]+$/gi).required()
      }
      if (!schema.is_required) {
        inputString = inputString
          .matches(/^-?[0-9/s/null]+$/gi)
          .transform((_: any, val: any) => String(val) || null)
          .notRequired()
          .nullable()
      }
      return inputString

    case inputTypes.DURATION:
      if (typeof schema.greater_or_equal !== 'undefined') {
        inputString = inputString.test('greater_or_equal', (val: any) => {
          if (schema.is_required) {
            return Number(val) >= schema.greater_or_equal
          }
          return Number(val) >= schema.greater_or_equal || val === null || val === ''
        })
      }
      if (typeof schema.greater_than !== 'undefined') {
        inputString = inputString.test('greater_than', (val: any) => {
          if (schema.is_required) {
            return Number(val) > schema.greater_than
          }
          return Number(val) > schema.greater_than || val === null || val === ''
        })
      }
      if (typeof schema.less_or_equal !== 'undefined') {
        inputString = inputString.test('less_or_equal', (val: any) => {
          if (schema.is_required) {
            return Number(val) <= schema.less_or_equal
          }
          return Number(val) <= schema.less_or_equal || val === null || val === ''
        })
      }
      if (typeof schema.less_than !== 'undefined') {
        inputString = inputString.test('less_than', (val: any) => {
          if (schema.is_required) {
            return Number(val) < schema.less_than
          }
          return Number(val) < schema.less_than || val === null || val === ''
        })
      }
      if (schema.is_required) {
        inputString = inputString
          .test('duration', 'int', (val: any) => Number.isInteger(Number(val)) && !(val || '').includes('.'))
          .test('positive', 'int', (val: any) => Number(val) >= 0)
          .required()
      }
      if (!schema.is_required) {
        inputString = inputString
          .notRequired()
          .nullable()
          .transform((_: any, val: any) => String(val) || null)
          .test('duration', 'int', (val: any) => Number.isInteger(Number(val)) && !(val || '').includes('.'))
          .test('positive', 'int', (val: any) => Number(val) >= 0 || val === null || val === '')
      }

      return inputString

    case inputTypes.NUMBER:
      if (typeof schema.greater_or_equal !== 'undefined') {
        inputString = inputString.test('num', 'Must be greater or equal', (val: any) => {
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && Number.isInteger(val)) {
            return BigInt(val) >= BigInt(schema.greater_or_equal)
          }
          return val >= schema.greater_or_equal
        })
      }
      if (typeof schema.greater_than !== 'undefined') {
        inputString = inputString.test('num', 'Must be greater than', (val: any) => {
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && Number.isInteger(val)) {
            return BigInt(val) > BigInt(schema.greater_than)
          }
          return val > schema.greater_than
        })
      }
      if (typeof schema.less_or_equal !== 'undefined') {
        inputString = inputString.test('num', 'Must be less or equal', (val: any) => {
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && Number.isInteger(val)) {
            return BigInt(val) <= BigInt(schema.less_or_equal)
          }
          return val <= schema.less_or_equal
        })
      }
      if (typeof schema.less_than !== 'undefined') {
        inputString = inputString.test('num', 'Must be less than', (val: any) => {
          if (!schema.is_required && (val === 'null' || val === null)) {
            return true
          }
          if (val && val !== 'null' && Number.isInteger(val)) {
            return BigInt(val) < BigInt(schema.less_than)
          }
          return val < schema.less_than
        })
      }
      if (schema.is_required) {
        inputString = inputString.required()
      }
      if (!schema.is_required) {
        inputString = inputString
          .notRequired()
          .nullable()
          .transform((_: any, val: any) => String(val) || null)
      }
      return inputString

    case inputTypes.DOUBLE:
      inputString = inputString.test('Is NaN', (val: any) => {
        if (val && val !== 'null' && val !== '0') {
          if (val.includes(',')) {
            return Number(val.replace(',', '.'))
          }
          return Number(val)
        }
        return true
      })
      if (typeof schema.greater_or_equal !== 'undefined') {
        inputString = inputString.test('Is greater or equal', (val: any) => {
          if (!schema.is_required && (value === '' || value === null)) return true
          return Number(val) >= schema.greater_or_equal
        })
      }
      if (typeof schema.greater_than !== 'undefined') {
        inputString = inputString.test('Is greater than', (val: any) => {
          if (!schema.is_required && (value === '' || value === null)) return true
          return Number(val) > schema.greater_than
        })
      }
      if (typeof schema.less_or_equal !== 'undefined') {
        inputString = inputString.test('Is less or equal', (val: any) => {
          if (!schema.is_required && (value === '' || value === null)) return true
          return Number(val) <= schema.less_or_equal
        })
      }
      if (typeof schema.less_than !== 'undefined') {
        inputString = inputString.test('Is less than', (val: any) => {
          if (!schema.is_required && (value === '' || value === null)) return true
          return Number(val) <= schema.less_than
        })
      }
      if (schema.is_required) {
        inputString = inputString.required()
      }
      if (!schema.is_required) {
        inputString = inputString
          .notRequired()
          .nullable()
          .transform((_: any, val: any) => String(val) || null)
      }
      return inputString

    case inputTypes.STRING:
      if (schema.is_email) {
        inputString = inputString.email()
      }
      if (schema.max_size) {
        inputString = inputString.max(schema.max_size)
      }
      if (schema.min_size) {
        inputString = inputString.min(schema.min_size)
      }
      if (schema.regex) {
        inputString = inputString.matches(schema.regex)
      }
      if (schema.is_required && schema.is_empty_as_null) {
        return inputString.required()
      }
      if (!schema.is_required) {
        return inputString
          .notRequired()
          .nullable()
          .transform((_: any, val: any) => String(val) || null)
      }
      return inputString

    case inputTypes.SECRET:
      if (schema?.max_size) {
        inputString = inputString.max(schema.max_size)
      }
      if (schema?.min_size) {
        inputString = inputString.min(schema.min_size)
      }
      if (schema?.is_required && schema.is_empty_as_null) {
        inputString = inputString.required()
      }
      if (!schema.is_required) {
        return inputString
          .notRequired()
          .nullable()
          .transform((_: any, val: any) => String(val) || null)
      }
      return inputString

    case inputTypes.BLOCK_ARRAY:
      if (schema?.is_required && schema.is_empty_as_null) {
        blockArray = blockArray.required()
      }
      return blockArray

    default:
      if (schema?.is_required) {
        inputString = inputString.required()
      }
      return inputString
  }
}
