import { ConfigurationsActionType } from '../../../types/configurations'

interface IAddActions {
  [key: string]: Function
}

const addActions: IAddActions = {
  [ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_TOP]: (state: any, name: string, data: any) => (state[name] = [data, ...state[name]]),
  [ConfigurationsActionType.BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM]: (state: any, name: string, data: any) => (state[name] = [...state[name], data]),
  [ConfigurationsActionType.BLOCK_ARRAY_CARD_ADD_ITEM]: (state: any, name: string, data: any) => (state[name] = [...state[name], data]),
}

export const blockArrayActions = {
  DND(state: any, path: string[], item: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name] = item)
    }

    if (currentPathLength < path.length) {
      this.DND(currentState?.[path[lenth - 1]], path, item, data, (currentPathLength += 1))
    }
  },
  tableModify(state: any, path: string[], index: any, data: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name][index] = data)
    }

    if (currentPathLength < path.length) {
      this.tableModify(currentState?.[path[lenth - 1]], path, index, data, (currentPathLength += 1))
    }
  },
  addItem(state: any, path: string[], data: any, lenth = 1, actionType: string = '') {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return addActions?.[actionType](state, name, data)
    }

    if (currentPathLength < path.length) {
      this.addItem(currentState?.[path[lenth - 1]], path, data, (currentPathLength += 1), actionType)
    }
  },
  deleteItem(state: any, path: string[], index: any, data?: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name] = state[name].filter((el: any, i: number) => i !== index))
    }

    if (currentPathLength < path.length) {
      this.deleteItem(currentState?.[path[lenth - 1]], path, index, data, (currentPathLength += 1))
    }
  },
  deleteItemCard(state: any, path: string[], data?: any, lenth = 1) {
    let currentPathLength: number = lenth
    const currentState: any = currentPathLength === 1 ? state?.productsConfigurations : state
    const name = path[path.length - 1]

    if (currentPathLength === path.length && typeof state[name] !== 'undefined') {
      return (state[name] = data)
    }

    if (currentPathLength < path.length) {
      this.deleteItemCard(currentState?.[path[lenth - 1]], path, data, (currentPathLength += 1))
    }
  },
}
