import { AppRightBar } from '@t4b/core/lib'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightBarActionType } from '../../types/rightbar'

const RightBarContainer: FC = () => {
  const dispatch = useDispatch()
  const { rightBarHidden, rightBarItem, fullScreen } = useSelector((state: any) => state.rightBar)

  const handleClick = (): void => {
    dispatch({ type: RightBarActionType.HIDE_RIGHT_BAR })
  }

  return (
    <AppRightBar
      className={`app-rightbar p-2 ${fullScreen && 'app-rightbar-full-screen'}`}
      hidden={rightBarHidden}
      items={rightBarItem}
      handleClick={handleClick}
    />
  )
}

export default RightBarContainer
