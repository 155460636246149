import React from 'react'
import * as yup from 'yup'
import SelectInput from './SelectInput'
import TextInput from './TextInput'
import CheckboxInput from './CheckboxInput'
import DoubleInput from './DoubleInput'
import IntegerInput from './IntegerInput'
import DateTimePicker from './DateTimePicker'
import { InputTypes, ISelectInputControl, ITextInputControl } from './types'
import PasswordInput from './PasswordInput'
import MultiSelectInput from './MultiSelectInput'

export function textInput(params: ITextInputControl) {
  const { name, isDisabled, placeholder, tooltipText, isColumn, isLabelHiden, label, className, errMsg, isRequired, isEmail } = params
  return {
    name,
    tooltipText,
    placeholder,
    isColumn,
    isLabelHiden,
    isRequired,
    errMsg,
    isEmail,
    label,
    className,
    type: InputTypes.TEXT,
    isDisabled,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function doubleInput(params: ITextInputControl) {
  const { name, isDisabled, placeholder, tooltipText, isColumn, isLabelHiden, label, precision, className, errMsg, isRequired } = params
  return {
    name,
    tooltipText,
    placeholder,
    isRequired,
    isColumn,
    isLabelHiden,
    precision,
    label,
    className,
    type: InputTypes.DOUBLE,
    isDisabled,
    errMsg,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function integerInput(params: ITextInputControl) {
  const { name, isDisabled, placeholder, tooltipText, isColumn, isLabelHiden, label, errMsg, validationType, className, isRequired } = params
  return {
    name,
    tooltipText,
    placeholder,
    isColumn,
    isLabelHiden,
    isRequired,
    errMsg,
    label,
    className,
    validationType,
    type: InputTypes.INTEGER,
    isDisabled,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function selectInput(params: ISelectInputControl) {
  const { name, options, isDisabled, state, setState, convert, tooltipText, selected, label, className, isColumn } = params
  return {
    name,
    tooltipText,
    selected,
    label,
    className,
    isColumn,
    type: InputTypes.SELECT,
    options,
    isDisabled,
    state,
    setState,
    convert,
    optionItems(opts: any[]) {
      this.options = opts
      return this
    },
    converter(converterFunc: any) {
      this.convert = converterFunc
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
  }
}

export function multiSelectInput(params: any) {
  const { name, options, state, setState, label } = params
  return {
    name,
    type: InputTypes.MULTI_SELECT,
    options,
    label,
    state,
    setState,
  }
}

export function CreatableMultiSelectInput(params: any) {
  const { name, options, state, setState, label, errMsg, isRequired, onCreateOption, schema, isViewOnly } = params
  return {
    name,
    errMsg,
    isRequired,
    onCreateOption,
    schema,
    isViewOnly,
    type: InputTypes.CREATABLE_MULTI_SELECT,
    options,
    label,
    state,
    setState,
  }
}

export function checkboxInput(name: string, description?: string, label?: string, className?: string, isDisabled?: boolean, checkboxType?: string, isNullable?: boolean) {
  return {
    name,
    label,
    className,
    isDisabled,
    checkboxType,
    isNullable,
    type: InputTypes.CHECKBOX,
    description,

    disabled(isDisabled: boolean) {
      if (isDisabled) {
        this.type = InputTypes.CHECKBOX_DISABLED
      }
      return this
    },
  }
}

export function dateTimePicker(name: string, params?: any, isRequired?: boolean) {
  return {
    name,
    isRequired,
    type: InputTypes.DATE_TIME_PICKER,
    params: params ? params : {},
  }
}

export function passwordInput(params: ITextInputControl) {
  const { name, isDisabled, placeholder, tooltipText, isColumn, isLabelHiden, errMsg, isRequired, label, className, validationSchema, inputType } = params
  return {
    name,
    tooltipText,
    placeholder,
    isColumn,
    inputType,
    isLabelHiden,
    isRequired,
    validationSchema,
    className,
    errMsg,
    label,
    type: InputTypes.PASSWORD,
    isDisabled,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function buildControlsExt(controlDescriptors: any[], state: any, setState: any, translation?: string, touched?: any, setTouched?: any, errors?: any) {
  return controlDescriptors.map((control: any) => {
    switch (control.type) {
      case InputTypes.TEXT:
        return (
          <TextInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            placeholder={control.placeholder}
            label={control.label}
            key={control.name}
            isDisabled={control.isDisabled}
            errorText={control.errMsg}
            tooltipText={control.tooltipText}
            isColumn={control.isColumn}
            isLabelHiden={control.isLabelHiden}
            className={control.className}
            isRequired={control.isRequired}
            isEmail={control.isEmail}
          />
        )
      case InputTypes.SELECT:
        return (
          <SelectInput
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            options={control.options}
            touched={touched}
            setTouched={setTouched}
            name={control.name}
            label={control.label}
            key={control.name}
            isDisabled={control.isDisabled}
            convert={control.convert}
            tooltipText={control.tooltipText}
            selected={control.selected}
            className={control.className}
            isColumn={control.isColumn}
          />
        )
      case InputTypes.CHECKBOX:
        return (
          <CheckboxInput
            state={state}
            setState={setState}
            touched={touched}
            setTouched={setTouched}
            name={control.name}
            label={control.label}
            key={control.name}
            className={control.className}
            description={control.description}
            isDisabled={control.isDisabled}
            checkboxType={control.checkboxType}
            isNullable={control.isNullable}
          />
        )
      case InputTypes.DOUBLE:
        return (
          <DoubleInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            precision={control.precision}
            placeholder={control.placeholder}
            label={control.label}
            key={control.name}
            isDisabled={control.isDisabled}
            errorText={control.errMsg}
            tooltipText={control.tooltipText}
            isColumn={control.isColumn}
            isLabelHiden={control.isLabelHiden}
            className={control.className}
            isRequired={control.isRequired}
          />
        )
      case InputTypes.INTEGER:
        return (
          <IntegerInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            placeholder={control.placeholder}
            label={control.label}
            key={control.name}
            isDisabled={control.isDisabled}
            errorText={control.errMsg}
            tooltipText={control.tooltipText}
            isColumn={control.isColumn}
            isLabelHiden={control.isLabelHiden}
            type={control.validationType}
            className={control.className}
            isRequired={control.isRequired}
          />
        )
      case InputTypes.DATE_TIME_PICKER:
        return (
          <DateTimePicker
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            valueKey={control.name}
            messageKey={control.name}
            schema={yup.date().required()}
            timeSchema={control.params.timeSchema}
            showTimeSelect={control.params.showTimeSelectOnly}
            showTimeSelectOnly={control.params.showTimeSelectOnly}
            timeIntervals={control.params.timeIntervals}
            key={control.name}
            className={''}
            description={control.params.description}
            isColumn={control.params.isColumn}
            minDate={control.params.minDate}
            maxDate={control.params.maxDate}
            isRequired={control.isRequired}
            timeFormat={control.params.timeFormat}
          />
        )
      case InputTypes.PASSWORD:
        return (
          <PasswordInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            placeholder={control.placeholder}
            label={control.label}
            key={control.name}
            isDisabled={control.isDisabled}
            errorText={control.errMsg}
            tooltipText={control.tooltipText}
            isColumn={control.isColumn}
            isLabelHiden={control.isLabelHiden}
            className={control.className}
            validationSchema={control.validationSchema}
            inputType={control.inputType}
            isRequired={control.isRequired}
          />
        )
      case InputTypes.MULTI_SELECT:
        return (
          <MultiSelectInput
            state={control.state ? control.state : state}
            setState={control.setState ?? setState}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            errorText={control.errMsg}
            label={control.label}
            options={control.options}
            key={control.name}
            style={control._style}
          />
        )
      case InputTypes.CREATABLE_MULTI_SELECT:
        return (
          <MultiSelectInput
            state={control.state ? control.state : state}
            setState={control.setState ?? setState}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            errorText={control.errMsg}
            label={control.label}
            options={control.options}
            key={control.name}
            style={control._style}
            isRequired={control.isRequired}
            onCreateOption={control.onCreateOption}
            schema={control.schema}
            isCreatable
          />
        )
    }
    return control.type
  })
}
